.vehicle-plans table {
  width: 100%;
  color: var(--primary-blue);
}

.vehicle-plans tr:nth-child(odd) {
  background: #f3f5f8 0% 0% no-repeat padding-box;
}

.titles-container {
  background: rgb(234, 5, 5);
}

.titles-container th {
  text-align: left;
  padding: 10px;
  color: var(--white);
  font-weight: 500;
  font-family: "FreightSansProMedium", serif;

  background: var(--primary-blue);
}

.titles-container2 th {
  text-align: left;
  padding: 16px;
  color: var(--white);
  font-size: 20px;
  font-weight: 500;
  font-family: "FreightSansProMedium", serif;

  background: var(--primary-blue);
}

.titles-container2 th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.titles-container2 th:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.table-list {
  visibility: collapse;
}

.table-list.table-list-2 {
  visibility: visible;
}

.table-list-show {
  visibility: visible;
}

.vehicle-plans .general-list-width {
  width: 64%;
}

.vehicle-plans .general-withoption-list-width {
  width: 50%;
}

.vehicle-plans tr td {
  font-family: "FreightSansProMedium", serif;
  color: var(--primary-blue);
  padding: 5px 10px;
}

.vehicle-plans tr td p {
  font-size: 17px;
}

.white-arrow {
  max-width: 10px;

  transform: rotate(90deg);
  transition: all ease-in-out 0.2s;
}

.white-arrow-rotate {
  width: 100%;
  max-width: 10px;
  transform: rotate(270deg);
  transition: all ease-in-out 0.2s;
}

.vehicle-plans-list {
  width: 100%;
  max-width: 690px;
}

.vehicle-plan .plan-title {
  width: 100%;
  height: 56px;
  padding: 0 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 21px #00000015;
  opacity: 1;
  border-left: 7px solid var(--orange);
}

.vehicle-plan .plan-title h6 {
  font-family: "FreightSansProBold", serif;
  color: var(--primary-blue);
}

.vehicle-plan .text-content {
  font-family: "FreightSansProMedium", serif;
  color: var(--primary-blue);
}

.vehicle-plan .plan-list-title {
  width: 100%;
  height: 49px;
  color: var(--white);
  background: var(--primary-blue) 0% 0% no-repeat padding-box;
  opacity: 1;
  font-family: "FreightSansProMedium", serif;
  font-size: 17px;
  padding: 0 15px;
}

.vehicle-plan .blue-arrow {
  width: 18px;
  height: 18px;
  transition: all ease-in-out 0.2s;
}

.blue-arrow-rotate {
  width: 18px;
  height: 18px;
  transition: all ease-in-out 0.2s;
  transform: rotate(180deg);
}

@media screen and (min-width: 768px) {
  .table-list {
    visibility: visible;
  }
}
@media screen and (min-width: 1024px) {
  .vehicle-plans-list.vehicle-plans-list--full {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 1280px) {
  .vehicle-plans-list {
    width: 100%;
    max-width: 850px;
  }
  .vehicle-plans-list.vehicle-plans-list--full {
    width: 100%;
    max-width: 100%;
  }
}

.table-list tr td {
  padding: 16px;
}

.table-list tr td:first-of-type {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

@media screen and (max-width: 1024px) {
  .table-list.table-list-2 tr td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    display: inline-block;
    margin-right: 5px;
  }

  .table-responsive .table-list.table-list-2 tr td {
    display: block;
    padding: 0 9px;
  }

  .table-responsive .table-list.table-list-2 tr td:first-of-type {
    padding-top: 10px;
  }

  .table-responsive .table-list.table-list-2 tr td:last-of-type {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 1024px) {
  .table-responsive table {
    table-layout: fixed;
  }
}
.vehicle-colum-slide .splide__list {
  width: 100%;
}

.vehicle-colum-slide .splide__arrow {
  background-color: transparent;
  top: 44%;
}

.vehicle-colum-slide .splide__arrow svg {
  height: 37px;
}

.vehicle-colum-slide .splide__arrow svg path {
  stroke: #00aef0;
}

.vehicle-colum-slide .splide__arrow[disabled] svg path {
  stroke: #e2e2e2;
}

.vehicle-colum-slide .splide__pagination__page.is-active {
  background-color: #f89420;
}

.vehicle-colum-slide .claims-channel-card.claims-channel-card--fix {
  margin: 0 auto;
}
