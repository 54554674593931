.providers-container {
  background: var(--gray-third) 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  padding: 34px 0;
}

.provider-card {
  background-color: #fff;
  border-radius: 6px;
  padding: 18px;
  box-shadow: 0px 3px 21px #0000000f;
  height: 68px;
}

.provider-card img {
  width: 100%;
  height: 50px;
  object-fit: contain;
}

.providers-container .title {
  font-size: 22px;
  margin-bottom: 25px;
}

.providers-container .hide-btn {
  text-align: center;
  color: var(--orange);
  font-family: "FreightSansProBold", serif;
}

.cards-container {
  margin-top: 25px;
  width: 100%;
  display: flex;
}

.cards-container .splide__arrows {
  margin-top: -120px;
  background: #000;
}

.cards-container .splide {
  width: 250px;
  height: 223px;
}

.cards-container .splide__slide {
  height: 223px;
}

.cards-container .splide__pagination__page {
  background-color: #ced3e2;
  width: 10px;
  height: 10px;
}

.cards-container .splide__pagination__page.is-active {
  background-color: #f89420;
  width: 10px;
  height: 10px;
}

.cards-container .splide__arrow {
  background-color: transparent;
}

.cards-container .splide__arrow--prev {
  margin-left: -50px;
}

.cards-container .splide__arrow--prev img {
  width: 20px;
}

.cards-container .splide__arrow--next {
  margin-right: -50px;
}

.cards-container .splide__arrow--next img {
  width: 20px;
  transform: rotate(180deg);
}

@media screen and (min-width: 375px) {
  .cards-container .splide {
    width: 284px;
    height: 223px;
  }
}

@media screen and (min-width: 768px) {
  .cards-container .splide {
    width: 420px;
    height: 250px;
  }
}

@media screen and (min-width: 1024px) {
  .providers-container {
    padding: 50px 0;
  }

  .providers-container .title {
    font-size: 32px;
    padding-bottom: 25px;
  }

  .provider-card {
    background-color: #fff;
    border-radius: 6px;
    padding: 32px 48px;
    box-shadow: 0px 3px 21px #0000000f;
    height: auto;
  }

  .provider-card img {
    width: 100%;
    height: 84px;
    object-fit: contain;
  }
}

.provider-list .splide__list {
  width: 100% !important;
}

.provider-list .splide__arrow--prev {
  left: -25px !important;
}

.provider-list .splide__arrow--next {
  right: -25px !important;
}

.provider-list .splide__arrow {
  background-color: transparent !important;
}
