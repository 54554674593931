@media screen and (min-width: 1024px) {
  .comparator-container .container {
    width: 100%;
    max-width: 1500px !important;
  }
}
.comparator-desktop-header .close-icon {
  float: right;
  margin-top: 20px;
}
.comparator-desktop-header .close-icon-image {
  width: 35px;
}
.comparator-desktop-title {
  display: inline-block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.comparator-desktop-title h1 {
  font-size: 30px;
  color: var(--primary-blue);
  text-align: center;
  font-family: "FreightSansProMedium", serif;
  margin-bottom: -15px;
}
.comparator-desktop-title .insurance-titles {
  text-align: center;
}
.comparator-desktop-title h2 {
  font-size: 45px;
  color: var(--primary-blue) !important;
  display: inline;
  font-family: "FreightSansProBold", serif;
  text-align: center;
}
.comparator-desktop-title h3 {
  font-size: 45px;
  color: var(--primary-blue) !important;
  display: inline;
  font-family: "FreightSansProBold", serif;
  text-align: center;
}
.selector-plans-container {
  display: flex;
  margin-top: 45px;
}

.selector-plans-container h6 {
  font: normal normal bold 20px "FreightSansProBold", serif;
  color: var(--primary-blue);
}
.selector-btn-container {
  display: flex;
  justify-content: space-between;
}
.selector-btn-container .selector-title {
  font: bold 16px "FreightSansProBold", serif;
}
.selector-btn-container .listbox-selector-button {
  background: #4ea8e2 url(../../../../assets/img/icons/chevron-down-white.svg)
    no-repeat 90%;
  border-radius: 16px 16px 0px 0px;
  opacity: 1;
  color: var(--white);
  height: 50px;
  text-align: center;
}
.selector-btn-container .selector-btn-box {
  width: 100%;
}

.plus-icon {
  width: 40px;
  cursor: pointer;
}

.ul-plans ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ul-plans li {
  font-family: "FreightSansProMedium", serif;
  text-align: center;
  color: var(--primary-blue);
  font-size: 16px;
  padding: 12px;
  height: 65px;
  margin-bottom: 3px;
}
.ul-plans-margin-remove li {
  margin-left: 0px;
}
.ul-plans li:nth-child(even) {
  background: #f3f5f8;
}

.ul-plans li:first-child {
  background: var(--primary-blue);
  color: var(--white);
  font: bold 17px "FreightSansProBold", serif;
  text-align: center;
  padding: 13px 0;
}

.ul-plans-titles {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ul-plans-titles li:nth-child(even) {
  background: #f3f5f8;
}
.ul-plans-titles li {
  font-family: "FreightSansProMedium", serif;
  padding: 12px;
  color: var(--primary-blue);
  border-radius: 5px 0 0 5px;

  height: 65px;
  margin-bottom: 3px;
}
.ul-plans-titles li:first-child {
  background: var(--primary-blue);
  color: var(--white);
  font: bold 17px "FreightSansProBold", serif;

  padding: 13px;
}

.last-rounded {
  border-radius: 0 5px 5px 0;
}

.li-hidden-with-color {
  color: var(--primary-blue) !important;
}

.blue-asterisk {
  color: var(--secondary-blue) !important;
}

@media screen and (min-width: 1440px) {
  .ul-plans li {
    font-family: "FreightSansProMedium", serif;
    text-align: center;
    color: var(--primary-blue);
    font-size: 16px;
    padding: 12px;
    height: auto;
  }
  .ul-plans-titles li {
    font-family: "FreightSansProMedium", serif;
    padding: 12px;
    color: var(--primary-blue);
    border-radius: 5px 0 0 5px;

    height: auto;
  }
}
.title-container h1,
.title-container h2,
.title-container h3 {
  color: var(--primary-blue) !important;
}
