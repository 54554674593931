.news-container .news-card {
  width: 100%;
}
.news-container .news-card .noticias__title {
  font-size: 25px;
  line-height: 27px;
}
.news-container .news-card .news-previewContent {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.news-container .news-card .news-previewContent p {
  color: #3a5e7f;
  font-size: 18px;
  font-family: "FreightSansProMedium", serif;
}
.news-container .news-card .noticias__link {
  font-size: 18px;
}
.news-container .news-card .news-previewContent img {
  display: none;
}
@media screen and (min-width: 1024px) {
  .news-card .img-container {
    width: 100%;
    max-width: 38%;
  }
  .news-container .news-card .img-container .noticias__image {
    width: 100%;
    object-fit: cover;
  }

  .news-container .news-card .noticias__date {
    font-size: 18px;
    line-height: 27px;
  }
  .news-container .news-card .news-text-content {
    width: 100%;
    max-width: 60%;
  }
  .news-container .news-card .news-previewContent p {
    margin-bottom: 3px;
  }
  .news-container .news-card .noticias__link {
    font-weight: bold;
  }
  /* Group view */
  .news-container .noticias__block {
    box-shadow: 0px 3px 21px #0000001a !important;
  }
  .news-container .noticias__block .img-container {
    width: 100%;
    max-width: 100%;
  }
  .news-container .noticias__block .img-container .noticias__image {
    width: 100%;
    object-fit: cover;
    height: 250px;
    box-shadow: 0px 3px 21px #264e721a;
  }
  .news-container .noticias__block .news-text-content {
    padding: 10px 15px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .news-container .noticias__block .date-title {
    display: flex;
    flex-direction: column-reverse;
  }
  .news-container .noticias__block .noticias__title {
    font-size: 18px;

    line-height: 24px;
    -webkit-line-clamp: unset;
    -webkit-box-orient: unset;
  }

  .news-container .noticias__block .news-previewContent {
    margin-bottom: 15px;
  }
  .news-container .noticias__block .news-previewContent p {
    font-size: 17px;
  }
}
.splide__slide .news-slider .news-card {
  margin: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 21px #0000000f;
  height: 350px;
  width: 285px;
  border-radius: 6px;
}

.splide__slide .news-slider .noticias__block .img-container {
  display: block;
}
.splide__slide .news-slider .noticias__block .img-container img {
  width: 100%;
  height: 202px;
  border-radius: 6px 6px 0 0;
}
.splide__slide .news-slider .noticias__date {
  font-size: 15px;
}
.splide__slide .news-slider .noticias__title {
  font-size: 17px;
}
.splide__slide .news-slider .noticias__block .news-text-content img {
  display: none;
}
.splide__slide .news-slider .noticias__block .news-previewContent {
  display: none;
}
.splide__slide .news-slider .noticias__block .date-title {
  display: flex;
  flex-direction: column-reverse;
  padding: 20px;
}

@media screen and (min-width: 470px) {
  .splide__slide .news-slider .noticias__block .img-container img {
    height: 220px;
  }
}
@media screen and (min-width: 1024px) {
  .splide__slide .news-slider .news-card {
    height: 420px;
    width: 100%;
    border-radius: 6px;
  }
  .splide__slide .news-slider .news-card .img-container {
    width: 100%;
    max-width: 100%;
  }
  .splide__slide .news-slider .noticias__block .img-container img {
    object-fit: cover;
    height: 250px;
  }
  .splide__slide .news-slider .noticias__date {
    font-size: 18px;
  }
  .splide__slide .news-slider .noticias__title {
    font-size: 20px;
  }
}
@media screen and (min-width: 1280px) {
  .splide__slide .news-slider .news-card {
    height: 480px;
  }

  .splide__slide .news-slider .noticias__block .img-container img {
    object-fit: cover;
    height: 300px;
  }
  .splide__slide .news-slider .noticias__date {
    font-size: 20px;
  }
  .splide__slide .news-slider .noticias__title {
    font-size: 22px;
  }
}
