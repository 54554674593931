.btn-cards a:visited {
  color: var(--primary-blue) !important;
}

.btn-card {
  width: 100%;
  max-width: 250px;
  padding-left: 15px;
  height: 48px;
  /* display: flex; */
  font-family: "FreightSansProBold", serif;
  align-items: center;
  color: var(--primary-blue);
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 21px #00000015;
}

.btn-card .blue-btn {
  border-left: 4px solid var(--secondary-blue);
}

.blue-btn {
  border-left: 4px solid var(--secondary-blue);
}

.orange-btn {
  border-left: 4px solid var(--orange);
}

.btn-title {
  font-size: 16px;
}

.icon-img {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.title-text-button-card {
  font-size: 18px;
  color: var(--primary-blue);
  font-family: "FreightSansProBold", serif;
}
.text-button-card {
  border-left: 4px solid var(--orange);
}
.text-button-card,
.text-button-card:visited,
.btn-card,
.btn-card:visited {
  color: var(--primary-blue) !important;
}

@media screen and (min-width: 768px) {
  .title-text-button-card {
    font-size: 20px;
    color: var(--primary-blue);
    font-family: "FreightSansProBold", serif;
  }

  .btn-title {
    font-size: 17px;
  }

  .btn-card {
    height: 70px;
  }
}
@media screen and (min-width: 1024px) {
  .btn-title {
    font-size: 18px;
  }
  .btn-card {
    max-width: 340px;
    height: 70px;
  }
}
@media screen and (min-width: 1280px) {
  .btn-card {
    max-width: 400px;
    height: 70px;
  }
}
