.top-ribbon {
  --tw-bg-opacity: 1;
  background-color: var(--primary-blue);
  padding: 12px 0;
}
.arrow {
  transform: rotate(90deg);
}
@media (min-width: 1024px) {
  .top-ribbon {
    padding: 16px 0;
  }
}

.top-ribbon__contact {
  display: flex;
  align-items: center;
}

.top-ribbon__contact span {
  font-family: "FreightSansProBook", serif;
  font-size: 17px;
  color: var(--white);
}

.top-ribbon__contact img {
  margin-right: 8px;
}

.top-ribbon__dropdown span {
  color: var(--white);
  font-size: 28px;
}
.top-ribbon__links {
  color: var(--white) !important;
  font-family: "FreightSansProBook", serif;
  display: flex;
  align-items: center;
  flex: 0 0 50%;
  justify-content: flex-end;
}

.top-ribbon-searchIcon {
  margin-left: 46px;
  cursor: pointer;
}

.top-ribbon__links a {
  color: var(--white) !important;
  margin-left: 92px;
  font-size: 20px;
  width: max-content;
}

.top-ribbon__links a:first-of-type {
  margin-left: 0;
}

.date {
  font-family: "FreightSansProBook", serif;
  font-size: 20px;
  color: var(--white);
}
