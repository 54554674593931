.directive-card {
  background-color: #f3f4f8;
  border-radius: 10px;
  padding: 16px 12px;
  text-align: center;
}

.title-tabs-director h2 {
  font-family: "FreightSansProBold", serif;
  font-size: 22px;
  text-align: center;
  margin-top: 22px;
  margin-bottom: 32px;
  color: #00aef0;
}

.directive-card h2 {
  color: #00aef0;
  font-family: "FreightSansProBold", serif;
  margin-bottom: 2px;
  font-size: 16px;
  width: 100%;
}

.directive-card p {
  color: #264e72;
  font-family: "FreightSansProMedium", serif;
  font-size: 14px;
  width: 100%;
}

.admin-card {
  background-color: #fff;
  box-shadow: 0px 4px 21px #0000000f;
  border-radius: 10px;
  padding: 18px 12px;
  text-align: center;
  width: 48%;
}

.admin-card img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 12px;
}

.admin-card h2 {
  color: #00aef0;
  font-size: 16px;
  margin-bottom: 5px;
  width: 100%;
  font-family: "FreightSansProBold", serif;
}

.admin-card h3 {
  color: #264e72;
  font-size: 14px;
  margin-bottom: 5px;
  width: 100%;
  font-family: "FreightSansProBold", serif;
  line-height: 18px;
}

.admin-card h4 span {
  color: #264e72;
  font-size: 14px;
  margin-bottom: 5px;
  font-family: "FreightSansProBold", serif;
}

.admin-card h4 {
  color: #264e72;
  font-size: 14px;
  margin-bottom: 5px;
  width: 100%;
  font-family: "FreightSansProMedium", serif;
}

.admin-card a {
  color: #264e72;
  font-size: 10px;
  font-family: "FreightSansProMedium", serif;
  margin-top: auto;
  width: 100%;
}

.admin-card.admin-card--blue {
  background-color: #f3f4f8;
  box-shadow: none;
  border-radius: 10px;
}
@media (min-width: 600px) and (max-width: 768px) {
  .admin-pictures {
    width: 50%;
  }
}

@media (min-width: 1024px) {
  .directive-card {
    padding: 24px 30px;
  }

  .admin-card {
    padding: 18px;
  }

  .directive-card h2 {
    margin-bottom: 6px;
    font-size: 24px;
  }

  .directive-card p {
    font-size: 14px;
    font-size: 22px;
  }

  .admin-card img {
    width: 190px;
    height: 190px;

    margin-bottom: 20px;
  }

  .admin-card h2 {
    font-size: 24px;
    margin-bottom: 12px;
  }

  .admin-card h3 {
    font-size: 22px;
    margin-bottom: 8px;
    line-height: 24px;
  }

  .admin-card h4 {
    font-size: 22px;
    margin-bottom: 8px;
  }

  .admin-card h4 span {
    font-size: 22px;
  }

  .admin-card p {
    font-size: 22px;
    margin-bottom: 8px;
  }

  .admin-card a {
    font-size: 14px;
  }
}

@media (min-width: 1280px) {
  .title-tabs-director h2 {
    font-size: 36px;
  }

  .admin-card {
    width: 31%;
  }
}
