.services-channels__title {
  text-align: center;
  font-size: 30px;
  font-family: "FreightSansProMedium", serif;
  @apply text-oscura !important;
  line-height: 32px;
}

.services-channels__title span {
  text-align: center;
  font-size: 30px;
  font-family: "FreightSansProMedium", serif;
  @apply text-oscura !important;
  line-height: 32px;
}

.services-channels__title b {
  font-family: "FreightSansProBold", serif;
  font-size: 30px;
}

.services-channels,
.services-slide {
  padding-bottom: 0 !important;
}

.services-carrousel .splide .container--fix {
  bottom: 50%;
  max-width: 95%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.services-carrousel .splide__track {
  transition: all ease-out 0.5s;
  padding: 20px;
}

.services-carrousel .splide__list {
  width: 100% !important;
  align-items: center;
}

.services-carrousel .splide__slide {
  background-color: #f3f4f8;
  width: 230px !important;
  font-family: "FreightSansProMedium", serif;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  height: 129px;
  border-radius: 7px;
  text-align: center;
  transition: all ease-out 0.1s;
  padding: 15px;
  border: 1px solid transparent;
  @apply text-oscura !important;
}

.services-carrousel .splide__slide img {
  width: 45px;
  height: 45px;
}

.services-carrousel .splide__slide.is-active img {
  width: 50px;
  height: 50px;
}

.services-carrousel .splide__slide.is-active h2 {
  width: 100%;
  @apply text-oscura !important;
  font-family: "FreightSansProMedium", serif !important;
  font-weight: 700;
}
.services-carrousel .splide__slide h2 {
  width: 100%;
  color: #264e72 !important;
  font-family: "FreightSansProMedium", serif !important;
  font-weight: 500;
}

.services-carrousel .splide__slide.is-active {
  background-color: #ffffffff;
  border-radius: 7px;
  transition: all ease-out 0.5s;
  transform: scale(1.09);
  box-shadow: 0px 3px 21px #0000000f;
  font-family: "FreightSansProBold", serif;
  border: 1px solid #f1f1f1 !important;
}

.services-carrousel .splide__arrow {
  background-color: transparent;
}

.services-carrousel .splide__arrow[disabled] path {
  stroke: #c3c3c3;
}

.services-carrousel .splide__arrow--prev {
  left: -1em;
  right: -1em;
}

.services-carrousel .splide__arrow svg {
  height: 48px;
}

.channel-info__icon {
  width: 100px;
  margin: 20px auto;
}

.channel-info__intro {
  font-family: "FreightSansProMedium", serif;
  font-size: 32px;
  @apply text-oscura !important;
  text-align: center;
  line-height: 36px;
  margin-bottom: -12px;
}

.channel-info__title {
  font-family: "FreightSansProbold", serif;
  font-size: 36px;
  @apply text-naranja !important;
  text-align: center;
  line-height: 36px;
  margin: 12px 0;
}

.channel-info__info {
  text-align: center;
}

.channel-info__text {
  font-family: "FreightSansProMedium", serif;
  font-size: 20px;
  text-align: center;
  @apply text-oscura !important;
}

.channel-info__text b {
  font-family: "FreightSansProbold", serif;
  font-size: 20px;
  text-align: center;
  @apply text-oscura !important;
}

.channel-info__text a,
.channel-info__text a:visited {
  font-family: "FreightSansProBold", serif;
  font-size: 20px;
  text-align: center;
  color: #00aef0 !important;
  display: block;
}

.channel-info__link {
  color: #f89420 !important;
  font-family: "FreightSansProSemiBold", serif;
  font-size: 20px;
  display: inline-flex;
  align-items: center;
}

.channel-info__link img {
  width: 9px;
  margin-top: 2px;
  margin-left: 10px;
}

.channel__text {
  text-align: center;
}

.info-slide .splide__list {
  width: 100% !important;
}

/* ---------------------------------------------- */

@media screen and (min-width: 1024px) {
  .services-channels__title {
    font-size: 50px;
    line-height: normal;
    font-family: "FreightSansProMedium", serif;
  }

  .services-channels__title span {
    font-size: 50px;
    line-height: normal;
    font-family: "FreightSansProMedium", serif;
  }

  .services-channels__title b {
    font-size: 50px;
    font-family: "FreightSansProBold", serif;
  }

  .channel__text {
    text-align: left;
  }

  .channel-info__intro {
    text-align: left;
  }

  .channel-info__title {
    text-align: left;
  }

  .channel-info__text a {
    text-align: left;
  }

  .channel-info__text {
    text-align: left;
  }

  .channel-info__icon {
    margin-top: 0;
    margin-bottom: 0;
  }
  .tpago-text {
    font-family: "FreightSansProBold", serif;
    font-size: 28px;
    @apply text-oscura !important;
  }

  .info-slide {
    padding: 25px 0;
  }
  .section.services-slide .splide__arrow--next {
    right: -2em;
  }
}

/* ------------------------------------------- */

@media screen and (min-width: 1200px) {
  .info-slide {
    padding: 96px 60px 0;
    width: 80%;
    margin: 0 auto;
  }

  .channel-info__icon {
    height: 200px;
    width: 200px;
  }

  .channel-info__intro {
    font-size: 38px;
  }

  .channel-info__title {
    font-size: 40px;
  }

  .channel-info__text {
    font-size: 24px;
  }

  .channel-info__link {
    font-size: 24px;
  }

  .services-carrousel .splide__slide {
    width: 242px !important;
    height: 148px;
  }

  .services-carrousel .splide__slide img {
    width: 56px;
    height: 56px;
  }

  .services-carrousel .splide__slide.is-active img {
    width: 60px;
    height: 60px;
  }

  .services-carrousel .splide__arrow--prev {
    left: -2em;
  }

  .services-carrousel .splide__arrow svg {
    height: 70px;
  }

  .tpago-logo {
    width: 250px;
  }

  .tpago-text {
    font-family: "FreightSansProBold", serif;
    font-size: 34px;
    @apply text-oscura !important;
  }

  .services-carrousel.services-carrousel--center .splide__list {
    justify-content: center;
  }
  .section.services-slide .splide__arrow--next {
    right: -3em;
  }
}

.advantages-card {
  width: 100%;
  margin-bottom: 20px;
}
.arrow-advantages-card {
  width: 8.47px;
  margin-right: 10px;
  display: inline-block !important;
}

.advantages-title {
  font-size: 20px !important;
  font-family: "FreightSansProBold", serif;
  letter-spacing: 0px;
  color: var(--primary-blue) !important;
  margin-bottom: 15px;
}
.advantages-card ul {
  margin-left: 6px;
}

.advantage-li {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
}

.advantage-p {
  font-family: "FreightSansProMedium", serif;
  font-size: 22px !important;
  margin-bottom: 10px;
  color: var(--primary-blue) !important;
}

.advantage-span {
  font-family: "FreightSansProMedium", serif;
  font-size: 18px;
  color: var(--primary-blue);
}
.extra-content {
  width: 100%;
  color: var(--primary-blue);
}
.extra-content-title {
  font-family: "FreightSansProBold", serif;
  font-size: 17px;
  margin-bottom: 10px;
}

.extra-content p {
  font-size: 15px;
  margin: 15px 0;
}

@media screen and (min-width: 1024px) {
  .advantages-title {
    font-size: 25px !important;
  }
}
@media screen and (min-width: 1280px) {
  .advantages-title {
    font-size: 30px !important;
  }
  .extra-content-title {
    font-size: 25px;
  }
}
