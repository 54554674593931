.filter-view {
  width: 100%;
}
.filter-view .date-filter {
  width: 100%;
}

.react-select-news__control,
.react-select-news__control:hover,
.react-select-news__control:focus,
.react-select-news__control:focus-visible,
.react-select-news__control:focus-within,
.react-select-news__control:active,
.react-select-news__control--is-focused,
.react-select-news__control--menu-is-opened {
  border: 2px solid #eaeaeae3 !important;
  outline: none !important;
  box-shadow: none !important;
}
.react-select-news__control--menu-is-open
  .react-select-news__dropdown-indicator {
  transform: rotate(-180deg);
}
.react-select-news__dropdown-indicator {
  transition: 0.5s;
}

.react-select-news__menu {
  margin-top: 0;
}
@media screen and (min-width: 1024px) {
  .filter-view .date-filter {
    width: 100%;
    max-width: 450px;
  }
  .filter-view
    .date-filter
    .react-select-news-container
    .react-select-news__control {
    height: 50px;
  }
  .filter-view h3 {
    font-size: 18px;
    font-family: "FreightSansProBold", serif;
    color: #264e72;
  }
  .filter-view .view-container {
    width: 100%;
    max-width: 180px;
  }
  .filter-view .view-container .btn-view {
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;
  }
  .filter-view .view-container .selected-view {
    background: #e1e1e1 0% 0% no-repeat padding-box;
  }
  .filter-view .view-container .view-icon {
    width: 100%;
    max-width: 20px;
  }
  .react-select-news__menu {
    margin-top: -7px;
  }
}
