.servicios .splide__slide .inner {
  overflow: hidden;
  position: relative;
}

.servicios .servicios__block div {
  font-size: 22px;
}
.servicios .servicios__block p {
  font-size: 18px;
}

.left-div {
  height: 336px;
  margin-bottom: 28px;
}
.servicios__info {
  z-index: 1;
}

.servicios-slide__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: right;
  z-index: 0;
  transition: all ease-out 0.5s;
  filter: contrast(150%) brightness(100%) opacity(2.5) grayscale(1);
  object-fit: cover;
}
.slide__img-filter {
  opacity: 0.8;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.inner:hover .servicios-slide__img {
  transform: scale(1.1);
}

.servicios-splide1 {
  display: none;
}

.servicios-splide2 {
  display: block;
}

.servicios__text {
  margin: 5px 0 17px 0 !important;
}
.servicios__link {
  color: #fff !important;
}

.servicios__link--white {
  color: #f89420 !important;
}
.servicios__title {
  line-height: 38px !important;
}

@media screen and (min-width: 768px) {
  .servicios .servicios__block div {
    font-size: 31px;
  }
}
@media screen and (min-width: 1024px) {
  .servicios .servicios__block p {
    font-size: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .servicios-splide1 {
    display: block;
  }
  .servicios-splide2 {
    display: none;
  }
  .servicios__text {
    width: 50%;
  }
}
