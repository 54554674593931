.middle-nav {
  padding: 25px 0;
}
.bottom-nav__links-icon {
  margin-right: 9px;
  max-width: 29px;
}

.bottom-nav__links span:last-child {
  display: none;
}
@media (min-width: 1200px) {
  .middle-nav {
    padding: 38px 0;
  }
}

.middle-nav__box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.middle-nav__logo {
  display: flex;
  flex-grow: 2;
  justify-content: center;
}

.middle-nav__logo img {
  width: 189px;
}

@media (min-width: 1200px) {
  .middle-nav__logo img {
    width: 260px;
  }
}

.middle-nav__menu img {
  width: 36px;
}

.middle-nav__links a {
  font-family: "FreightSansProBold", serif;
  text-transform: uppercase;
  color: var(--primary-blue);
}

.middle-nav__links a:hover {
  color: var(--secondary-blue);
}

@media (min-width: 1024px) {
  .middle-nav__links a {
    margin-left: 16px;
  }
  .middle-nav__logo {
    justify-content: flex-start;
  }
}

@media (min-width: 1200px) {
  .middle-nav__links a {
    margin-left: 32px;
  }
}

.middle-nav__links a.office {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 148, 32, var(--tw-bg-opacity));
  color: var(--white);
  text-transform: none;
  padding: 12px 52px;
  margin-left: 37px;
  transition: all ease-out 0.9s;
  display: inline-flex;
  align-items: center;
}

.middle-nav__links a.office:hover span {
  transition: all ease-out 0.3s;
  transform: rotate(90deg);
}

.middle-nav__links a.office span {
  font-weight: bold;
  margin-left: 24px;
  transition: all ease-out 0.9s;
  display: inline-block;
}

.middle-nav__links a.office:hover {
  opacity: 0.8;
}

.bottom-nav {
  background-color: var(--gray-primary);
  padding: 18px 0;
}

.bottom-nav__links span {
  color: #c4c4c4;
}

.bottom-nav__links a {
  display: flex;
  color: var(--primary-blue);
  font-family: "FreightSansProBold", serif;
  font-size: 16px;
  height: fit-content;
}

.bottom-nav__links a:last-of-type {
  padding-right: 0;
  border-right: none;
  margin-right: 0;
}

.bottom-nav__links a:hover {
  color: var(--secondary-blue);
}

.office {
  --tw-bg-opacity: 1;
  background-color: var(--orange);
  color: var(--white);
  text-transform: none;
  padding: 12px 52px;
  margin-left: 37px;
  transition: all ease-out 0.9s;
  display: inline-flex;
  align-items: center;
  font-family: "FreightSansProBold", serif;
}
.office:focus {
  outline: none;
}
.office img {
  width: 8px;

  margin-left: 15px;
  transform: rotate(0);
  transition: transform 0.3s;
}

.office .arrow-rotate {
  transform: rotate(90deg);
}
.container {
  position: relative;
}

.more-links-box {
  transition: opacity 1s ease-out;
  opacity: 0;
  font-family: "FreightSansProBold", serif;
  z-index: 3;
  top: 65px;
  position: absolute;
  right: 2%;
  width: 100%;
  max-width: 366px;
  height: 125px;
  background: var(--white) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 21px #0000001a;
  visibility: hidden;
  padding: 20px;

  flex-direction: column;
  justify-content: space-around;
}
.more-links-box a {
  font-size: 20px;
  color: var(--primary-blue);
}
.more-links-box-show {
  display: flex;
  visibility: visible;
  opacity: 1;
  transition: opacity ease-out 0.5s;
}

@media screen and (min-width: 1200px) {
  .more-links-box {
    top: 85px;
  }
}
