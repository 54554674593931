.side-bar-container {
  background: #f3f4f8 0% 0% no-repeat padding-box;
  opacity: 1;
  color: var(--primary-blue);
  padding: 25px;
  margin-bottom: 25px;
}

.side-bar-list .list-title {
  font-size: 20px;
  font-family: "FreightSansProBold", serif;
  margin-bottom: 10px;
}

.side-bar-list .text-content p {
  font-family: "FreightSansProMedium", serif;
  margin-bottom: 10px;
  font-size: 18px;
  color: var(--primary-blue);
}

.side-bar-list ul {
  padding: 0 10px;
  margin-bottom: 15px;
}

.side-bar-list .side-bar-list-item {
  display: flex;
  align-items: baseline;
  font-family: "FreightSansProMedium", serif;
}

.side-bar-list.side-bar-list--blue .side-bar-list-item span {
  display: flex;
  font-size: 18px;
  color: #264e72;
  align-items: baseline;
  font-family: "FreightSansProMedium", serif;
}

.side-bar-list .side-bar-list-item img {
  width: 7.47px;
  margin-right: 10px;
}

@media screen and (min-width: 1024px) {
  .side-bar-container {
    width: 100%;
    max-width: 400px;
  }

  .side-bar-list .list-title {
    font-size: 25px;
    font-family: "FreightSansProBold", serif;
    margin-bottom: 10px;
  }

  .side-bar-list.side-bar-list--blue .side-bar-list-item span {
    display: flex;
    font-size: 20px;
    color: #264e72;
    align-items: baseline;
    font-family: "FreightSansProMedium", serif;
  }
}
