.footer {
  background-color: var(--primary-blue);
}

@media (min-width: 1024px) {
  .footer {
    padding: 60px 0;
  }
}

.footer .disclaimer {
  font-family: "FreightSansProMedium", serif;
}

.footer__collapse-link {
  color: var(--white);
  font-size: 25px;
  font-family: "FreightSansProMedium", serif;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ffffff36;
  padding: 12px 0;
  width: 100%;
}

@media (min-width: 1024px) {
  .footer__collapse-link {
    border-bottom: none;
  }
}

@media (min-width: 1024px) {
  .footer__cert-box img {
    margin-left: auto;
  }
}

.footer__link-list li {
  margin-bottom: 6px;
}

.footer__link-list a {
  font-family: "FreightSansProMedium", serif;
  color: var(--white);
  font-size: 16px;
}

.footer__title {
  color: var(--white);
  font-size: 25px;
  padding: 12px 0;
  font-family: "FreightSansProMedium", serif;
}

footer .footer__social-box {
  margin-top: 20px;
}

@media (min-width: 1280px) {
  .footer__social-box {
    display: flex;
    justify-content: flex-start;
  }
}

.footer__social-box a {
  margin-right: 4px;
}

@media (min-width: 768px) {
  .footer__social-box a {
    margin-right: 12px;
  }
}

.footer__social-box a:last-child {
  margin-right: 0;
}

.footer__social-box a:hover {
  transition: all ease-in-out 1s;
  opacity: 1;
}

.footer__social-box a:hover img {
  transition: all ease-in-out 0.2s;
  transform: scale(1.2);
}

.footer__contact {
  display: flex;
  color: var(--white);
  margin-bottom: 12px;
  font-family: "FreightSansProMedium", serif;
  align-items: flex-start;
}
.footer__contact:visited {
  color: var(--white) !important;
}

.footer__contact span {
  flex: 1;
}

.footer__contact .blue {
  color: #89c6fc;
}

.footer__contact:last-of-type {
  margin-bottom: 0;
}

.footer__contact img {
  margin-right: 9px;
}

.footer__logo {
  margin: 0 auto;
  margin-bottom: 32px;
}

.footer__cert-box {
  margin-top: 32px;
}

.footer__bottom {
  text-align: center;
  margin-top: 32px;
}

.footer__bottom a {
  color: var(--white);
  font-family: "FreightSansProMedium", serif;
}

.footer__bottom span {
  color: var(--white);
}

@media (min-width: 768px) {
  .footer__bottom {
    border-top: 1px solid #ffffff36;
  }
}
