.options-title {
  font-size: 22px;
  color: var(--primary-blue);
  font-family: "FreightSansProBold", serif;
  margin-bottom: 30px;
}

.blue-box {
  background: #264d71 0% 0%;
  box-shadow: 0px 3px 21px #0000000f;
  border-radius: 10px;
  align-self: flex-start;
}

.sub-logo {
  width: 92px;
  align-self: flex-start;
  margin-bottom: 12px;
}

.subsidiaries-box h2 {
  font-size: 18px;
  margin: 15px 0;
  color: #264d71;
  font-family: "FreightSansProMedium", serif;
}

.subsidiaries-box a {
  color: #f89420;
  font-family: "FreightSansProSemiBold", serif;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.subsidiaries-box a img {
  width: 8px;
  margin-left: 12px;
}

.tabs-controls-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.tab-control {
  font-size: 18px;
  font-family: "FreightSansProMedium", serif;
  color: #6e6e6e;
  text-align: center;
  width: 100%;
  max-width: 50% !important;
  border-bottom: 3px solid var(--gray-primary);
  padding-bottom: 5px;
  cursor: pointer;
}
.tab-control-active {
  color: var(--primary-blue);
  font-family: "FreightSansProBold", serif;
  border-bottom: 7px solid var(--orange);
}

.content-container li img {
  width: 7px;
  margin-right: 5px;
}

.content-container li {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  font-size: 18px;
  color: var(--primary-blue);
  font-family: "FreightSansProMedium", serif;
}

.content-container li b {
  font-family: "FreightSansProBold", serif;
  font-size: 20px;
}

.content-container li .orange {
  font-family: "FreightSansProBold", serif;
  color: #f89420;
  font-size: 20px;
}

.content-container li.via-item {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  font-size: 24px !important;
  color: var(--primary-blue);
  font-family: "FreightSansProMedium", serif;
}

.content-container .vias-number {
  color: #00aef0;
  font-size: 25px;
  margin-right: 24px;
}

.content-container span.text {
  font-size: 20px;
}

.content-container h4 {
  color: var(--primary-blue);
  font-family: "FreightSansProSemibold", serif;
  font-size: 18px;
  margin-bottom: 10px;
}

.content-container p {
  font-size: 18px;
  margin: 15px 0;
  color: var(--primary-blue);
  font-family: "FreightSansProMedium", serif;
}
.content-container p b {
  font-family: "FreightSansProMedium", serif;
  font-size: 17px;
}

.blue-box p {
  color: #fff;
}

.blue-box a {
  color: #f89420;
  font-family: "FreightSansProSemiBold", serif;
}

.blue-box .logo {
  width: 152px;
}

.blue-box a img {
  width: 8px;
  margin-left: 12px;
}

@media screen and (min-width: 768px) {
  .options-title {
    font-size: 24px;
  }

  .tab-control {
    font-size: 20px;
  }

  .content-container h4 {
    font-size: 20px;
  }

  .content-container p b {
    font-family: "FreightSansProMedium", serif;
    font-size: 17px;
  }
}
@media screen and (min-width: 1024px) {
  .options-title {
    font-size: 30px;
  }

  .subsidiaries-box a {
    font-size: 20px;
  }

  .blue-box .logo {
    width: 250px;
    margin-right: 60px;
  }

  .option-filiales .content-container p {
    font-size: 22px;
    margin: 22px 0;
  }

  .blue-box p {
    font-size: 22px;
    margin-top: 0;
  }

  .blue-box {
    padding: 36px;
  }

  .blue-box a {
    font-size: 20px;
  }

  .sub-logo {
    width: 200px;
    align-self: flex-start;
    margin-bottom: 0px;
  }

  .subsidiaries-box .image-box {
    width: 150px;
    flex: 0 0 150px;
  }

  .subsidiaries-box h2 {
    font-size: 20px;
    margin-top: 0;
    color: #264d71;
    font-family: "FreightSansProMedium", serif;
  }

  .subsidiaries-box .sub-text {
    margin-left: 60px;
  }

  .option-filiales .tabs-controls-container,
  .option-filiales .content-container {
    max-width: 800px !important;
    margin: 0 auto;
  }

  .tab-control {
    font-size: 22px;
  }

  .content-container h4 {
    font-size: 22px;
  }

  .content-container li b {
    font-family: "FreightSansProBold", serif;
    font-size: 24px;
  }

  .content-container span.text {
    font-size: 24px;
  }

  .content-container .vias-number {
    font-size: 37px;
  }

  .content-container li .orange {
    font-size: 24px;
  }

  .content-containe li {
    font-size: 24px;
  }

  .content-containe p {
    font-size: 24px;
  }
}
