.hero-menus {
  background: var(--primary-blue);
  height: 231px;
  width: 100%;
  position: relative;
}

.hero-menus img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-size: cover;
  background-position: center center;
}

.hero-menus .text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  color: var(--white);
  height: 231px;
}

.hero-menus .text-content h2 {
  font-size: 28px;
  margin-bottom: -20px;
  font-family: "FreightSansProMedium", serif;
  color: #fff !important;
}

.hero-menus .text-content h3 {
  font-size: 30px;
  font-family: "FreightSansProBold", serif;
  margin-top: 16px;
  line-height: 34px;
  color: #fff !important;
}

@media screen and (min-width: 1024px) {
  .hero-menus img {
    width: 100%;
    object-fit: cover;
    min-height: 231px;
  }
}

@media screen and (min-width: 1440px) {
  .hero-menus {
    height: 375px;
    width: 100%;
  }

  .hero-menus img {
    width: 100%;

    height: 375px;
  }

  .hero-menus .text-content {
    height: 375px;
  }

  .hero-menus .text-content h2 {
    font-size: 52px;
  }

  .hero-menus .text-content h3 {
    font-size: 52px;
    line-height: 52px;
  }
}
