.sectionInformation {
  width: 100%;
  color: var(--primary-blue);
  background: var(--white);
}

.title-div {
  margin: 25px 0;
}
.title-div h4 {
  font-size: 23px;
  font-family: "FreightSansProMedium", serif;
  margin-bottom: -5px;
  color: var(--primary-blue) !important;
}
.title-div h5 {
  font-size: 25px;
  font-family: "FreightSansProBold", serif;
  color: var(--primary-blue) !important;
}

.text-div h4 {
  display: none;
  font-size: 23px;
  font-family: "FreightSansProMedium", serif;
  margin-bottom: -5px;
  color: var(--primary-blue) !important;
}
.text-div h5 {
  display: none;
  font-size: 25px;
  font-family: "FreightSansProBold", serif;
  color: var(--primary-blue) !important;
}

@media screen and (min-width: 768px) {
  .text-div div {
    display: block;
  }
  .text-div h4 {
    display: block;
  }
  .text-div h5 {
    display: block;
  }
}
.img-div {
  width: 100%;
}
.img-div img {
  width: 100%;
}

.text-div {
  margin-top: 25px;
  margin-bottom: 35px;
}
.text-div p {
  margin-top: 25px;
  font-size: 18px;
  font-family: "FreightSansProMedium", serif;
}

@media screen and (min-width: 425px) {
  .text-div h5 {
    font-size: 34px;
  }
  .title-div h5 {
    font-size: 34px;
  }
}

@media screen and (min-width: 768px) {
  .sectionInformation {
    margin-top: 50px;
  }
  .img-div {
    width: 100%;
    max-width: 680px;
  }
}

@media screen and (min-width: 1024px) {
  .title-div {
    margin: 0;
  }
  .sectionInformation {
    margin: 0;
    padding: 91px 0;
  }
  .text-div {
    padding: 0;
    margin: 0;
    width: 100%;
    margin-left: 40px;
  }
}

@media screen and (min-width: 1300px) {
  .title-div {
    margin: 0;
  }
  .sectionInformation {
    margin: 0;
    padding: 91px 0;
    height: 550px;
  }
  .text-div {
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 700px;
  }

  .img-div {
    width: 100%;
    max-width: 458px;
  }
}
@media screen and (min-width: 1440px) {
  .text-div div {
    margin: 0;
  }
  .title-div {
    margin: 0;
  }
  .sectionInformation {
    margin: 0;
    padding: 91px 0;
  }
  .text-div {
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 725px;
  }

  .text-div h4 {
    font-size: 30px;
    margin-bottom: -15px;
  }
  .text-div h5 {
    margin-bottom: 0px;
    font-size: 40px;
    margin-top: 10px;
  }
  .title-div h4 {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .title-div h5 {
    margin-bottom: 0px;
    font-size: 40px;
  }

  .img-div {
    width: 100%;
    max-width: 458px;
  }
  .text-div p {
    margin-top: 25px;
    font-size: 22px;
    font-family: "FreightSansProMedium", serif;
  }
}
