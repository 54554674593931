.respo-card {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.respo-card:last-child {
    margin-bottom: 0;
}

.respo-card img {
    width: 50px;
    height: 50px;
    margin-right: 18px;
    object-fit: contain;
}

.respo-card h2 {
    font-family: "FreightSansProBold", serif;
    font-size: 16px;
    color: #264E72;
}

@media (min-width: 768px) {
    .respo-card {
        margin: 0;
    }
}

@media (min-width: 1200px) {
    .respo-card {
        display: block;
        text-align: center;
    }

    .respo-card img {
        margin: 0 auto;
        width: 100px;
        height: 100px;
    }

    .respo-card h2 {
        font-size: 24px;
        margin-top: 24px;
    }
}