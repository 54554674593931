.motor-vehicles {
  width: 100%;
  color: var(--primary-blue);
  background: var(--white);
}

.text-information h2 {
  font-size: 20px;
  font-family: "FreightSansProMedium", serif;
  margin-bottom: -5px;
  color: var(--primary-blue) !important;
}
.text-information h3 {
  font-size: 25px;
  font-family: "FreightSansProBold", serif;
  color: var(--primary-blue) !important;
}

.text-information p {
  font-family: "FreightSansProMedium", serif;
  margin: 15px 0;
  color: var(--primary-blue) !important;
}

.text-information p b {
  font-family: "FreightSansProMedium", serif;
  font-size: 17px;
}

@media screen and (min-width: 768px) {
  .motor-vehicles {
    margin-top: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .motor-vehicles {
    margin: 30px 0;
  }
  .text-information {
    width: 100%;
    max-width: 700px;
  }
  .text-information h2 {
    font-size: 24px;
    color: var(--primary-blue) !important;
    margin-bottom: -15px;
  }
  .text-information h3 {
    font-size: 30px;
    color: var(--primary-blue) !important;
  }

  .text-information p {
    font-family: "FreightSansProMedium", serif;
    font-size: 18px;
    color: var(--primary-blue) !important;
  }
  .text-information p b {
    font-family: "FreightSansProMedium", serif;
    font-size: 19px;
  }
}
@media screen and (min-width: 1280px) {
  .text-information {
    width: 100%;
    max-width: 850px;
    margin-top: 50px;
    /*     margin-bottom: 60px; */
  }
  .text-information h2 {
    font-size: 35px;

    margin-bottom: -15px;
    color: var(--primary-blue) !important;
  }
  .text-information h3 {
    font-size: 40px;
  }

  .text-information p {
    font-family: "FreightSansProMedium", serif;
    font-size: 20px;
    color: var(--primary-blue) !important;
  }
}
