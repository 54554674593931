.top .splide__slide.is-active {
  border: none !important;
  color: var(--secondary-blue);
}
.top .splide__slide {
  position: relative;
  color: var(--gray-secondary);
  font-weight: 600;
  margin-left: -10px;
  font-family: "FreightSansProMedium", serif;
  margin-bottom: -2px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.top .is-visible {
  color: var(--gray-secondary);
}

.top .splide__slide img {
  width: 65px;
  height: 65px;
}
.top .splide__slide span {
  font-size: 10px;
  padding-top: 5px;
  padding-bottom: 8px;
}

.top .splide__slide.is-active .splide-border {
  bottom: 2px;
}
.top .splide__slide .splide-border {
  position: absolute;
  height: 5px;
  width: 100%;
  background: var(--secondary-blue);
  bottom: 0;
}
.top .splide__slide .splide-border-disable {
  position: absolute;
  height: 5px;
  width: 100%;
  background: rgba(177, 179, 181, 0.3);
  bottom: 0;
}

.top {
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 375px) {
  .top .splide__slide span {
    font-size: 0.8rem;
    padding: 10px 0;
  }
}

@media screen and (min-width: 425px) {
  .top .splide__slide span {
    font-size: 14px;
  }
}

@media screen and (min-width: 1024px) {
  .top .splide__slide img {
    width: 75px;
    height: 75px;
  }
  .top .splide__slide span {
    font-size: 18px;
  }
  .top .splide__slide .splide-border {
    height: 6px;
  }
  .top .splide__slide .splide-border-disable {
    height: 6px;
  }
}
