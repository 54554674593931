.bubbles {
  background-color: #ffffff;
  box-shadow: 0px 0px 46px #00000010;
  border-radius: 20px;
  padding: 25px;
  align-items: flex-end;
  margin-bottom: 22px;
  min-height: 171px;
}

.bubbles__icon {
  align-self: self-start;
  width: 28px;
  height: 28px;
}

.bubbles__icon.bubbles__icon--desktop {
  display: none;
}

.bubbles__icon-text {
  font-family: "FreightSansProBold", serif;
  font-size: 18px;
  color: #264e72;
  margin-left: 15px;
  line-height: 24px;
}

.bubbles__link {
  color: #f89420;
  font-family: "FreightSansProBold", serif;
  font-size: 16px;
  margin-top: 8px;
}
.bubbles__link:visited {
  color: #f89420 !important;
}

.bubbles__text {
  color: #264e72;
  font-family: "FreightSansProMedium", serif;
  font-size: 16px;
  margin-top: 8px;
}

.bubbles__title {
  display: flex;
  margin-bottom: 12px;
}

.bubbles:last-of-type {
  margin-bottom: 0;
}
.contact-form__sent {
  cursor: pointer;
}

.contact-form__input {
  background-color: #f8f9fb !important;
  border: 1px solid #cdd4e0 !important;
  width: 100% !important;
  border-radius: 12px !important;
  height: 57px !important;
  font-family: "FreightSansProMedium", serif;
  padding: 12px 22px !important;
  color: rgba(38, 78, 114) !important;
  margin-bottom: 10px !important;
}

.contact-form__textarea {
  background-color: #f8f9fb;
  border: 1px solid #cdd4e0;
  width: 100%;
  border-radius: 12px;
  font-family: "FreightSansProMedium", serif;
  padding: 12px 22px;
  color: rgba(38, 78, 114);
  margin-bottom: 10px;
  height: 186px;
  resize: none;
}

.contact-form__textarea::placeholder {
  color: rgba(38, 78, 114, 0.76);
}

.contact-form__input::placeholder {
  color: rgba(38, 78, 114, 0.76);
}

.contact-form__select {
  border: 1px solid #cdd4e0 !important;
  width: 100% !important;
  border-radius: 12px !important;
  height: 57px !important;
  font-family: "FreightSansProMedium", serif;
  padding: 12px 22px !important;
  color: rgba(38, 78, 114) !important;
  margin-bottom: 10px !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background: url(../../../assets/img/icons/chevron-form.svg) #f8f9fb no-repeat
    94%;
  background-size: 17px !important;
}

.contact-form__select:invalid {
  color: rgba(38, 78, 114, 0.76) !important;
}

.contact-form__button {
  border: 2px solid #f89420;
  border-radius: 8px;
  color: #f89420;
  background-color: transparent;
  font-family: "FreightSansProMedium", serif;
  padding: 12px 12px;
  text-align: center;
  margin-bottom: 10px;
  display: block;
  font-size: 19px;
  width: 100%;
}

.contact-form__button.contact-form__button--solid {
  border: 2px solid transparent;
  background-color: #f89420;
  color: #ffffff;
}

.contact-form__button.contact-form__button--solid.isDisabled,
.contact-form__button.contact-form__button--solid.isDisabled:hover {
  background-color: #c4c4c4;
  cursor: not-allowed !important;
}
.contact-form {
  padding-top: 30px;
}

.contact-form__title {
  font-size: 25px;
  font-family: "FreightSansProBold", serif;
  color: #264e72;
  margin-bottom: 20px;
  line-height: 24px;
}

.contact-form__subtitle {
  font-size: 15px;
  font-family: "FreightSansProMedium", serif;
  color: #264e72;
  margin-bottom: 20px;
}

@media (min-width: 1024px) {
  .contact-form__input {
    height: 72px;
    font-size: 24px;
    margin-bottom: 12px;
  }

  .bubbles {
    display: flex;
    align-items: flex-start;
  }

  .contact-form__select {
    height: 72px;
    font-size: 24px;
    margin-bottom: 12px;
  }

  .contact-form__textarea {
    font-size: 24px;
    margin-bottom: 36px;
    height: 216px;
  }

  .contact-form__button {
    font-size: 24px;
    padding: 16px;
    margin-bottom: 0;
  }

  .contact-form__button:hover {
    background-color: #f89420;
    border: transparent;
    color: #ffffff;
    transition: all ease-out 0.5s;
  }

  .contact-form__button.contact-form__button--solid:hover {
    background-color: transparent;
    border: 2px solid #f89420;
    color: #f89420;
    transition: all ease-out 0.5s;
  }

  .bubbles__icon-text {
    font-size: 20px;
    margin-left: 0;
  }

  .bubbles__link {
    font-size: 16px;
  }

  .bubbles__icon {
    align-self: center;
    width: 48px;
    height: 48px;
    display: none;
  }

  .bubbles__icon.bubbles__icon--desktop {
    width: 48px;
    height: 48px;
    display: block;
    align-self: flex-start;
    margin-right: 24px;
  }

  .bubbles__text {
    font-size: 15px;
    margin-top: 0;
  }

  .bubbles__title {
    margin-bottom: 0;
    display: block;
  }
  .contact-form__subtitle {
    text-align: right;
  }
}
