.header-news {
  width: 100%;
  height: 500px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 21px #264e721a;
}

.header-news .img-container {
  width: 60%;
}
.header-news .img-container img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}
.header-news .news-text-content {
  width: 38%;
  padding: 40px 25px;
}

.header-news .news-text-content .news-previewContent {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.header-news .news-text-content .noticias__date {
  font-size: 20px;
}
.header-news .news-text-content .noticias__title {
  font-size: 35px;
  width: 350px;
  line-height: 1;
}
.header-news .news-text-content .news-previewContent p {
  font-size: 20px;
  color: #3a5e7f;

  font-family: "FreightSansProMedium", serif;
}
.header-news .news-previewContent img {
  display: none;
}
