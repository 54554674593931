.claims-section h2 {
  font-size: 20px;
  font-family: "FreightSansProMedium", serif;
  color: var(--primary-blue);
}

.claims-section h1 {
  font-size: 24px;
  font-family: "FreightSansProBold", serif;
  color: var(--primary-blue);
}

.claims-section p {
  font-family: "FreightSansProMedium", serif;
  font-size: 18px;
  margin: 15px 0;
  color: var(--primary-blue);
}

.card-claim {
  background-color: #fff;
  box-shadow: 0px 0px 21px #00000015;
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  transition: all ease-out 0.5s;
}

.card-claim:hover {
  background-color: var(--primary-blue);
}

.card-claim img {
  width: 40px;
  margin-right: 41px;
}

.card-claim h2 {
  color: var(--primary-blue);
  font-family: "FreightSansProBold", serif;
  font-size: 17px;
  flex: 1;
}

.card-claim:hover h2 {
  color: #fff;
}

.card-claim:hover img {
  transform: scale(1.1);
}

.card-claim:hover .card-claims__icon {
  display: none;
}

.card-claim:hover .card-claims__hover.hidden {
  display: block;
}

.claims-card-box {
  max-width: 900px;
}

/* 1024 */

@media (min-width: 1024px) {
  .claims-section h2 {
    font-size: 24px;
  }

  .claims-section h1 {
    font-size: 30px;
  }

  .claims-section p {
    font-size: 18px;
  }

  .card-claim {
    display: block;
    padding: 20px;
  }

  .card-claim h2 {
    text-align: center;
    font-size: 20px;
  }

  .card-claim img {
    width: 80px;
    height: 80px;
    margin: 24px auto;
  }
}

/* 1200 */

@media (min-width: 1280px) {
  .claims-section h2 {
    font-size: 43px;
    text-align: center;
    margin-bottom: -25px;
  }

  .claims-section h1 {
    font-size: 55px;
    text-align: center;
  }

  .claims-section p {
    font-size: 24px;
    margin-top: 37px;
    text-align: center;
  }
}
