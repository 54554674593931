.secondary-menu {
  width: 100%;
  background: #00000014;
  z-index: 3;
  position: relative;
  top: -20px;
}

.menu-bar {
  width: 100%;
  height: 50px;
  background: var(--primary-blue);
  border-bottom: 8px solid var(--orange);
  display: flex;
  align-items: center;
  padding: 0 30px;
  cursor: pointer;
  z-index: 10;
}

.inner-menu-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-bar .title-menu div {
  display: flex;
}

.menu-bar .title-menu h2 {
  color: var(--white);
  font-size: 18px;
  font-family: "FreightSansProBold", serif;
  display: inline;
}
.menu-bar .title-menu h3 {
  color: var(--white);
  font-size: 18px;
  font-family: "FreightSansProBold", serif;
  margin-left: 3px;
}
.menu-bar .menu-icon {
  width: 20px;
  height: 20px;
}
.menu-bar .arrow-icon {
  transform: rotate(0deg);
  transition: all ease-in 0.3s;
}
.menu-bar .arrow-icon-rotate {
  transform: rotate(90deg);
  transition: all ease-in 0.3s;
}

.btn-containers {
  justify-content: space-between;
  height: 46px;
  width: 100%;
  max-width: 500px;
  padding: 0 30px;
}
.btn-containers .menu-btn img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.btn-containers .menu-btn span {
  font-family: "FreightSansProBold", serif;
  color: var(--primary-blue);
  font-size: 14px;
}
.menu-modal {
  display: none;
  position: absolute;
  top: 50px;
  width: 100%;
  background: var(--white) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 21px #00000014;
  opacity: 1;
  z-index: 3;
}

.menu-modal ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 15px 0;
}
.menu-modal-list {
  width: 100%;
  max-width: 400px;
  padding: 15px 60px;
  font-family: "FreightSansProMedium", serif;
  font-size: 19px;
  color: var(--primary-blue);
}
.menu-modal-list:visited {
  color: var(--primary-blue);
}
.menu-modal-list:hover,
.menu-modal-list li:hover {
  color: var(--secondary-blue);
  cursor: pointer;
}
.menu-modal-list:visited {
  color: var(--primary-blue) !important;
}
.menu-modal-list:hover {
  color: var(--secondary-blue);
  cursor: pointer;
}

.menu-modal-arrow {
  width: 10px;
}
.menu-modal-arrow-rotate {
  transform: rotate(90deg);
  width: 10px;
}

.menu-modal-show {
  display: flex;
}
.secondary-sub-menu {
  background: #f3f5f7;
  display: none;
  height: 100.05%;
  left: 360px;
  max-width: 320px;
  position: absolute;
  top: 0;
  width: 100%;
  border-top: 8px solid var(--white);
  border-bottom: 8px solid var(--white);
  border-right: 8px solid var(--white);
}

.secondary-sub-menu-show {
  display: flex;
  align-items: flex-start;
}

.secondary-sub-menu a {
  font-family: "FreightSansProMedium", serif;
  color: var(--primary-blue);
  padding: 9px 30px;
}

.secondary-sub-menu a:hover {
  color: var(--secondary-blue);
  cursor: pointer;
}

.secondary-sub-menu-mobile {
  width: 100%;
  background: #f3f5f7;
  margin: 0 20px;
}

.secondary-sub-menu-mobile li {
  padding: 10px 40px;

  font-family: "FreightSansProMedium", serif;
  color: var(--primary-blue);
}
@media screen and (min-width: 640px) {
  .menu-bar {
    width: 100%;
    height: 50px;
    background: var(--primary-blue);
    border-bottom: 8px solid var(--orange);
    display: flex;
    align-items: center;
    padding: 0 50px;
  }

  .btn-containers {
    padding: 0 50px;
  }
}

@media screen and (max-width: 768px) {
  .secondary-menu .container {
    width: 100%;
    max-width: 800px;
    padding: 0;
    margin: 0;
  }
}
@media screen and (min-width: 710px) {
  .menu-bar {
    width: 100%;
    height: 50px;
    background: var(--primary-blue);
    border-bottom: 8px solid var(--orange);
    display: flex;
    align-items: center;
    padding: 0 80px;
  }
  .menu-modal-list {
    padding: 15px 0;
    padding-left: 110px;
  }
  .secondary-sub-menu-mobile {
    margin: 0 70px;
  }

  .secondary-sub-menu-mobile li {
    padding: 10px 40px;
  }

  .btn-containers {
    padding: 0 80px;
    max-width: 600px;
  }
}

@media screen and (min-width: 768px) {
  .inner-menu-bar {
    padding: 0;
  }
  .secondary-menu {
    width: 100%;
    top: 0;
  }

  .menu-bar {
    border-top: none;
    width: 100%;
    max-width: 360px;
    position: relative;
    padding: 0 20px;
  }

  .menu-modal {
    max-width: 360px;
    padding-left: 50px;
  }
  .menu-modal-list {
    padding: 10px 0;
  }
  .btn-containers {
    width: 100%;
    justify-content: flex-end;
    padding: 0 20px;
  }
  .menu-modal-arrow-rotate {
    transform: rotate(0deg);
    width: 10px;
  }
}

@media screen and (min-width: 1024px) {
  .menu-bar .title-menu h2 {
    color: var(--white);
    font-size: 22px;
    font-family: "FreightSansProBold", serif;
  }
  .menu-bar .title-menu h3 {
    color: var(--white);
    font-size: 22px;
    font-family: "FreightSansProBold", serif;
    margin-left: 5px;
  }
  .menu-bar {
    height: 60px;
  }

  .menu-modal {
    justify-content: flex-start;
    padding-left: 20px;
  }

  .menu-modal ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /*  padding: 10px 0; */
  }

  /* .menu-modal-list {
    font-size: 24px;
  } */

  .menu-modal-list {
    font-size: 16px;
  }
  .w95:hover {
    width: 95%;
  }
  .menu-modal-list:hover {
    background: #f3f5f7 0% 0% no-repeat padding-box;
  }

  .btn-containers .menu-btn span {
    font-family: "FreightSansProBold", serif;
    color: var(--primary-blue);
    font-size: 18px;
  }
  .btn-containers .menu-btn img {
    width: 27px;
    height: 27px;
    margin-right: 10px;
  }

  .btn-containers {
    width: 100%;
    padding: 0;
  }
}
.sub-menu-li {
  display: none;
}
.sub-menu-li-show {
  display: block;
}

@media screen and (min-width: 1280px) {
  .btn-containers .menu-btn span {
    font-family: "FreightSansProBold", serif;
    color: var(--primary-blue);
    font-size: 19px;
  }
  .btn-containers .menu-btn img {
    width: 31px;
    height: 31px;
    margin-right: 10px;
  }
  .btn-containers {
    width: 100%;
    max-width: 70%;
    padding: 0;
  }
}
