.multirisk-plans h2 {
  font-family: "FreightSansProBold", serif;
  font-size: 25px;
  color: var(--secondary-blue);
  margin-bottom: 20px;
}

.multirisk-plans hr {
  margin: 20px 0;

  border: 1px solid var(--gray-third);
}
@media screen and (min-width: 1024px) {
  .multirisk-plans h2 {
    font-size: 30px;
  }
}
@media screen and (min-width: 1280px) {
  .multirisk-plans h2 {
    font-size: 35px;
  }
}
