.intro-section-text h2 {
    font-size: 20px;
    font-family: "FreightSansProMedium", serif;
    color: var(--primary-blue);
    text-align: center;
}

.intro-section-text h1 {
    font-size: 24px;
    font-family: "FreightSansProBold", serif;
    color: var(--primary-blue);
    text-align: center;
}

.intro-section-text p {
    font-family: "FreightSansProMedium", serif;
    font-size: 18px;
    margin: 15px 0;
    color: var(--primary-blue);
}

/* 1024 */

@media (min-width: 1024px) {
    .intro-section-text h2 {
        font-size: 24px;
    }

    .intro-section-text h1 {
        font-size: 30px;
    }

    .intro-section-text p {
        font-size: 18px;
    }


}

/* 1200 */

@media (min-width: 1280px) {
    .intro-section-text h2 {
        font-size: 43px;
        text-align: center;
        margin-bottom: -25px;
    }

    .intro-section-text h1 {
        font-size: 55px;
        text-align: center;
    }

    .intro-section-text p {
        font-size: 24px;
        margin-top: 37px;
        text-align: center;
    }
}