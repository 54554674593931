.cards {
  width: 100%;
  height: 70px;
  background: var(--white);
  box-shadow: 0px 0px 21px #00000015;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  transition: all 0.8s ease-in-out;
}
.cards-image-only {
  width: 100%;
  height: 70px;
  background: var(--white);
  box-shadow: 0px 0px 21px #00000015;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  transition: all 0.8s ease-in-out;
}
.card-img {
  width: 100%;
  max-width: 40px;
  margin-right: 15px;
  transition: all 0.8s ease-in-out;
}

.card-image {
  width: 100%;
  transition: all 0.8s ease-in-out;
}
.card-image-only {
  width: 100%;
  max-width: 180px;
}

.card-title {
  color: var(--primary-blue);
  font-family: "FreightSansProBold", serif;
}
.values-card-fix {
  box-shadow: 0px 3px 21px #0000000f;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
}
.card-box-slide {
  width: 100%;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--white);
  height: 80%;
}

.card-box-slide .card-img {
  width: 100%;
  max-width: 72px;
  margin-right: 0px;
  margin-bottom: 15px;
}

.card-box-slide .card-title {
  font-size: 20px;
}

.card-modal-container {
  width: 100%;
}
.card-modal-title {
  font-family: "FreightSansProBold", serif;
  font-size: 25px;
  margin: 15px 0;
  color: var(--primary-blue);
}

.card-modal-container p {
  font-family: "FreightSansProMedium", serif;
  font-size: 16px;
  color: var(--primary-blue);
  display: inline;
}

.cardPageLink {
  font-family: "FreightSansProMedium", serif;
  font-size: 16px;
  color: var(--primary-blue);
  display: inline;
}
.cardPageLink:visited {
  color: var(--primary-blue);
}

.card-modal-text {
  font-family: "FreightSansProMedium", serif;
  font-size: 16px;
  color: var(--primary-blue);
}

.modal-btns-container {
  margin-top: 15px;
  color: var(--primary-blue);
  font-family: "FreightSansProBold", serif;
  font-size: 15px;
}
.modal-btn {
  margin-bottom: 10px;
  padding-left: 10px;
  width: 245px;
  height: 48px;
  background: var(--white) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 21px #00000015;
  outline: none;
  color: var(--primary-blue);
}

.modal-btn:visited {
  color: var(--primary-blue) !important;
}

.modal-btns-container .blue-btn {
  border-left: 4px solid var(--secondary-blue);
}

.modal-btns-container .orange-btn {
  border-left: 4px solid var(--orange);
}

.modal-btns-container .icon-img {
  margin-right: 10px;
  width: 25px;
  height: 24px;
}

.list-container {
  font-family: "FreightSansProMedium", serif;
  font-size: 18px;
  color: var(--primary-blue);
  margin: 10px 0;
}
.list-container .list-title {
  font-family: "FreightSansProBold", serif;
  font-size: 19px;
  margin-bottom: 10px;
}
.list-container ul {
  list-style: none !important;
  margin-bottom: 15px;
}
.list-container ul p {
  font-family: "FreightSansProBold", serif;
  font-size: 19px;
  margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .list-container ul {
    margin-bottom: 0px;
  }
}
.list-container li {
  display: flex;
  align-items: baseline;
  margin-bottom: 5px;
}

.list-container li img {
  margin-right: 12px;
  width: 12px;
  height: 12px;
}

.list-container li a,
.list-container li a:visited {
  color: var(--primary-blue) !important;
}
@media screen and (min-width: 375px) {
  .card-box-slide {
    max-width: 284px;
    height: 85%;
  }
}

@media screen and (min-width: 768px) {
  .card-box-slide {
    max-width: 420px;
    height: 90%;
  }
  .card-box-slide .card-img {
    margin-top: 10px;
    width: 100%;
    max-width: 92px;
  }

  .card-modal-text {
    margin-bottom: 10px;
    font-size: 18px;
  }

  .list-container div {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-top: 1rem;
  }
}
@media screen and (min-width: 1024px) {
  .cards {
    padding: 40px 0;
    width: 100%;
    height: 223px;
    background: var(--white);
    box-shadow: 0px 0px 21px #00000015;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.8s ease-in-out;
  }
  .cards-image-only {
    padding: 40px 0;
    width: 100%;
    height: 223px;
    background: var(--white);
    box-shadow: 0px 0px 21px #00000015;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .card-img {
    width: 100%;
    min-width: 80px;
    max-height: 80px;
    margin-right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .card-img img {
    width: 100%;
    height: 60px;
    transition: all 0.8s ease-in-out;
  }

  .cards .card-title {
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
    width: 80%;
  }

  .cards:hover {
    background-color: var(--primary-blue);
    color: var(--white);
  }
  .cards:hover .card-title {
    color: var(--white);
  }
  .cards:hover .card-img .card-image {
    transform: scale(1.3);
    transition: all 0.8s ease-in-out;
  }
  .cards:hover .card-img .card-image-hover {
    transform: scale(1.3);
    transition: all 0.8s ease-in-out;
  }

  .cards:hover .card-image {
    display: none;
  }

  .cards:hover .card-image-hover {
    display: block;
  }
}
@media screen and (min-width: 1024px) {
  .link-flex a {
    width: 31%;
  }

  .claim-card {
    height: 100%;
  }
}
