.error-block {
  text-align: center;
}

.error-block h1 {
  color: #f89420;
  font-size: 39px;
  font-family: "FreightSansProBold", serif;
}

.error-block p {
  font-family: "FreightSansProMedium", serif;
  font-size: 18px;
  color: #264e72;
}

.error-block p span {
  font-family: "FreightSansProBold", serif;
  font-size: 18px;
  color: #00aef0;
}

.error-block a {
  font-family: "FreightSansProBold", serif;
  font-size: 18px;
  color: #264e72;
  border: 2px solid #264e72;
  padding: 16px 48px;
  display: inline-block;
  border-radius: 8px;
  margin: 15px 0;
  transition: all ease-out 0.5s;
}

.error-block a:hover {
  color: #fff;
  background-color: #264e72;
  border: 2px solid transparent;
}

@media (min-width: 1024px) {
  .error-block {
    text-align: left;
  }

  .error-block h1 {
    font-size: 84px;
  }

  .error-block p {
    font-size: 24px;
  }

  .error-block p span {
    font-size: 24px;
  }

  .error-block a {
    font-size: 24px;
    margin-top: 25px;
  }
}
