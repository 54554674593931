.immigration-plan-title {
  width: 100%;
  height: 56px;
  padding: 0 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 21px #00000015;
  opacity: 1;
  border-left: 7px solid var(--orange);
  margin: 10px 0;
  color: var(--primary-blue);
  font-family: "FreightSansProBold", serif;
  font-size: 18px;
}

.immigration-table {
  width: 100%;
}
.immigration-table tbody {
  width: 100%;
}
.immigration-table .title-container {
  text-align: left;
  background: var(--primary-blue) 0% 0% no-repeat padding-box;
  font: 16px;
  font-family: "FreightSansProMedium", serif;
  font-weight: medium;
  color: var(--white);
}
.immigration-table .title-container td {
  font-family: "FreightSansProMedium", serif;
  font-weight: medium;
  color: var(--white);
}
.immigration-table td {
  padding: 10px 20px;

  border-style: solid none;
  text-align: left;
  font-family: "FreightSansProMedium", serif;
  color: var(--primary-blue);
}
.immigration-table tr:nth-child(even) {
  background: #f3f5f8 0% 0% no-repeat padding-box;
}

.immigration-table td:first-child {
  border-left-style: solid;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.immigration-table td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

@media screen and (min-width: 1280px) {
  .immigration-table .title-container td {
    font-family: "FreightSansProMedium", serif;
    font-weight: medium;
    color: var(--white);
    font-size: 18px;
  }
  .immigration-table td {
    padding: 10px 20px;

    border-style: solid none;
    text-align: left;
    font-family: "FreightSansProMedium", serif;
    color: var(--primary-blue);
    font-size: 18px;
  }
}
