.publication-search-box {
  width: 100%;
}

.publication-search-box p {
  font-size: 16px;
  font-family: "FreightSansProSemiBold", serif;
  color: #484848;
  margin-bottom: 6px;
}

.react-select__control,
.react-select__control:hover,
.react-select__control:focus,
.react-select__control:focus-visible,
.react-select__control:focus-within,
.react-select__control:active,
.react-select__control--is-focused,
.react-select__control--menu-is-opened {
  border: 2px solid #eaeaeae3 !important;
  outline: none !important;
  box-shadow: none !important;
}
.react-select__control--menu-is-open .react-select__dropdown-indicator {
  transform: rotate(-180deg);
}
.react-select__dropdown-indicator {
  transition: 0.5s;
}

@media (min-width: 320px) and (max-width: 768px) {
  .react-select-container {
    margin-bottom: 12px;
  }
}
.publication-search-box__select {
  width: 100%;
  border: 2px solid #eaeaeae3;
  border-radius: 10px;
  height: 57px;
  margin-bottom: 12px;
  font-family: "FreightSansProMedium", serif;
  padding: 12px;
  font-size: 18px;
  color: #264e72;
  background-image: url(../../../assets/img/arrow-dark-blue.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 16px) 50%;
}

.publication-search-box__input {
  width: 100%;
  border: 2px solid #eaeaeae3 !important;
  border-radius: 10px;
  height: 57px;
  font-family: "FreightSansProMedium", serif;
  padding: 12px 0 12px 60px !important;
  font-size: 18px;
  color: #264e72;
  background-image: url(../../../assets/img/search.svg);
  background-repeat: no-repeat;
  background-position: 16px 50%;
}
.publication-search-box__input:hover,
.publication-search-box__input:active,
.publication-search-box__input:focus,
.publication-search-box__input:focus-visible,
.publication-search-box__input:focus-within {
  border-color: #eaeaeae3 !important;
  outline: none !important;
}
.publication-search-box__input::placeholder {
  color: #eaeaeae3;
}

.publication-results li {
  border-bottom: 2px solid #eaeaeae3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 36px 12px;
}

.publication-results li:last-of-type {
  border: none;
}

.publication-results li .download-img {
  margin-right: 0;
}

.publication-results li img {
  width: 38px;
  margin-right: 15px;
}

.publication-results li p {
  display: -webkit-box;
  color: #264e72;
  font-family: "FreightSansProBold", serif;
  font-size: 20px;
  line-height: 20px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.publication-results .info {
  margin-right: 15px;
}

.publication-results li h2 {
  font-size: 16px;
  font-family: "FreightSansProMedium", serif;
  color: #00000070;
}

.pagination-costum .paginationUL .pageItem .pageButton {
  width: 30px !important;
  height: 30px !important;
  border-radius: 4px !important;
  font-family: "FreightSansProBold", serif !important;
  box-shadow: 0px 3px 6px #0000001a !important;
  font-size: 16px !important;
  color: #264e72 !important;
  background-color: #fff !important;
  transition: all ease-out 0.5s !important;
}

.pagination-costum .paginationUL .pageItem .pageButton:hover {
  color: #fff !important;
  background-color: #264e72 !important;
}

.pagination-costum {
  margin-top: 24px !important;
}

.pagination-costum .paginationUL .pageItem .activeButton {
  color: #fff !important;
  box-shadow: 0px 3px 6px #0000001a !important;
  background-color: #264e72 !important;
}

@media (min-width: 1024px) {
  .publication-search-box {
    border-color: #fff;
    box-shadow: 0px 0px 21px #00000017;
    border-radius: 14px;
    padding: 12px;
  }

  .publication-search-box p {
    font-size: 18px;
    font-family: "FreightSansProSemiBold", serif;
    color: #264e72;
    margin-bottom: 0;
    flex: 0 0 auto;
  }

  .publication-search-box__select {
    margin-bottom: 0;
    flex: 0 0 20%;
  }

  .publication-search-box__input {
    margin-bottom: 0;
    flex: 0 0 20%;
  }

  .pagination-costum .paginationUL .pageItem .pageButton {
    width: 40px !important;
    height: 40px !important;
  }

  .pagination-costum .paginationUL .pageItem .activeButton {
    color: #fff !important;
    box-shadow: 0px 3px 6px #0000001a !important;
    background-color: #264e72 !important;
  }
}

@media (min-width: 1024px) {
  .publication-results li {
    border: none;
    background-color: #f3f4f8;
    border-radius: 18px;
    margin-bottom: 20px;
  }

  .publication-results li:last-of-type {
    margin-bottom: 0;
  }

  .publication-results li a {
    background-color: #f89420;
    border-radius: 10px;
    padding: 12px 24px;
    font-family: "FreightSansProSemiBold", serif;
    color: #fff;
    transition: all ease-out 0.5s;
  }

  .publication-results li a:hover {
    background-color: #f89420;
    opacity: 0.8;
  }

  .publication-results li a img {
    margin-right: 12px;
    width: 30px;
  }
}

@media (min-width: 1200px) {
  .publication-search-box {
    padding: 16px;
    border-radius: 18px;
  }

  .publication-results li {
    padding: 24px;
  }

  .publication-search-box__select {
    margin-bottom: 0;
    flex: 0 0 20%;
  }

  .publication-search-box__input {
    margin-bottom: 0;
    flex: 0 0 20%;
  }
}
