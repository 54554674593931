.claims-step-info.vehicle-plans-list {
  margin-top: 36px;
}

.required-documents {
  padding: 24px 0 !important;
}

.claims-step-info.vehicle-plans-list div h3 {
  font-size: 20px;
  font-family: "FreightSansProBold", serif;
  color: #264e72;
  margin-bottom: 20px;
}

.claims-step-info.vehicle-plans-list div p {
  font-size: 20px;
  font-family: "FreightSansProMedium", serif;
  color: #264e72;
  margin-bottom: 20px;
}

.claims-step-info.vehicle-plans-list div .link-border {
  font-size: 18px;
  font-family: "FreightSansProBold", serif;
  border: 2px solid #f89420;
  border-radius: 8px;
  color: #f89420;
  padding: 15px 30px;
  margin-bottom: 20px;
  display: inline-block;
  transition: all ease-out;
  text-decoration: none;
  text-align: center;
}
.claims-step-info.vehicle-plans-list div .link-border:active {
  text-decoration: none !important;
}

.claims-step-info.vehicle-plans-list div .link-border:hover {
  background-color: #f89420;
  border: 2px solid transparent;
  color: #fff;
}

.claims-step-info.vehicle-plans-list div .btn-toogle {
  display: flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 21px #00000015;
  border-left: 5px #00aef0 solid;
  padding: 20px;
  font-family: "FreightSansProBold", serif;
  font-size: 16px;
  color: #264e72;
  max-width: 300px;
  justify-content: space-between;
  align-items: center;
}

.claims-step-info.vehicle-plans-list div .btn-toogle img {
  width: 12px;
}

.required-documents h2 {
  color: #00aef0;
  font-family: "FreightSansProBold", serif;
  margin-bottom: 24px;
  font-size: 18px;
}

.required-documents h3 {
  color: #004379 !important;
  font-family: "FreightSansProBold", serif !important;
  margin-bottom: 36px !important;
  font-size: 16px !important;
}

.required-documents ul {
  margin-bottom: 36px !important;
}

.required-documents ul:last-of-type {
  margin-bottom: 0 !important;
}

.required-documents ul li {
  font-size: 16px;
  font-family: "FreightSansProMedium", serif;
  color: #264e72;
  margin-bottom: 20px;
  display: flex;
  align-items: baseline;
}

.required-documents ul li img {
  margin-right: 12px;
  width: 6px;
}
.required-documents p {
  font-size: 16px !important;
  font-family: "FreightSansProMedium", serif;
}

.required-documents p b {
  font-size: 16px !important;
  font-family: "FreightSansProSemibold", serif;
}

.location p {
  margin-bottom: 0px !important;
}

.location {
  margin-bottom: 24px;
}

.location:last-of-type {
  margin-bottom: 0 !important;
}

.location img {
  width: 24px;
  align-items: baseline;
  margin-right: 24px;
}

@media (min-width: 1024px) {
  .claims-step-info.vehicle-plans-list {
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .claims-step-info.vehicle-plans-list div h3 {
    font-size: 46px;
    max-width: 600px;
    line-height: 44px;
  }

  .claims-step-info.vehicle-plans-list div p {
    font-size: 22px;
    max-width: 600px;
    margin-bottom: 48px;
  }

  .claims-step-info.vehicle-plans-list div .btn-toogle {
    font-size: 18px;
    max-width: 400px;
  }

  .claims-step-info.vehicle-plans-list div .btn-toogle img {
    width: 18px;
  }

  .required-documents {
    padding: 30px 0 !important;
  }

  .required-documents h2 {
    font-size: 20px;
  }

  .required-documents h3 {
    font-size: 20px !important;
    line-height: 24px;
  }

  .required-documents ul li {
    font-size: 20px;
  }
  .required-documents p {
    font-size: 20px !important;
    font-family: "FreightSansProMedium", serif;
  }

  .required-documents p b {
    font-size: 20px !important;
    font-family: "FreightSansProSemibold", serif;
  }
}

.claims-channels-button {
  cursor: pointer;
}
