.footer-text {
  color: #264e72;
  font-family: "FreightSansProMedium", serif;
  font-size: 16px;
}

.how-modal__info div {
  display: flex;
  align-items: baseline;
  margin-bottom: 36px;
  position: relative;
}

.how-modal__title {
  font-family: "FreightSansProBold", serif;
  margin-bottom: 18px;
  color: #264e72;
  font-size: 24px;
  line-height: 24px;
}

.how-modal__text p {
  font-family: "FreightSansProMedium", serif;
  margin-bottom: 18px;
  color: #264e72;
  font-size: 18px;
  line-height: 30px;
}

.how-modal__text strong {
  font-family: "FreightSansProBold", serif;
  margin-bottom: 36px;
  color: #f89420;
  font-size: 18px;
  line-height: 30px;
}

.how-modal__info div:before {
  position: absolute;
  content: "";
  width: 1px;
  background-color: #00aef0;
  height: calc(100% + 4px);
  left: 16px;
  top: 32px;
}

.step-box img {
  width: 90%;
  margin: 50px auto;
  height: 448px;
}

.step-box:last-of-type .how-modal__info div:before {
  display: none;
}
.modal-step .steps-vehicle ul {
  counter-reset: my-awesome-counter;
}

.modal-step .steps-vehicle ul li {
  font-family: "FreightSansProMedium", serif;
  counter-increment: my-awesome-counter;
  color: #264e72;
  font-size: 18px;
  position: relative;
  margin-bottom: 32px;
  padding-left: 48px;
}

.modal-step .steps-vehicle ul li:before {
  content: counter(my-awesome-counter) " ";
  background-color: #f89420;
  color: #fff;
  font-family: "FreightSansProBold", serif;
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  left: 0;
  font-size: 16px;
  line-height: 24px;
}

.modal-step h2 {
  font-size: 32px;
  font-family: "FreightSansProMedium", serif;
  color: #004379;
}
.modal-step .steps-vehicle ul li:after {
  content: "";
  position: absolute;
  width: 1px;
  background-color: #f89420;
  height: calc(100% + 6px);
  right: calc(100% - 16px);
  top: 28px;
}

.modal-step .steps-vehicle ul li:last-child::after {
  display: none;
}
.steps-vehicle p {
  font-size: 18px !important;
  margin-bottom: 12px !important;
  font-family: "FreightSansProMedium", serif !important;
}

.steps-vehicle h1 {
  font-size: 24px !important;
  margin-bottom: 12px !important;
  font-family: "FreightSansProBold", serif !important;
  color: #00aef0 !important;
}

.steps-vehicle b {
  font-size: 18px !important;
  margin-bottom: 12px !important;
  font-family: "FreightSansProBold", serif !important;
}

.steps-vehicle span {
  font-size: 18px !important;
  margin-bottom: 12px !important;
  font-family: "FreightSansProBold", serif !important;
}

.how-modal__info--span {
  width: 32px;
  height: 32px;
  background-color: #00aef0;
  border-radius: 50%;
  color: #fff;
  flex: 0 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "FreightSansProBold", serif;
  margin-right: 12px;
}

.how-modal__info p {
  font-family: "FreightSansProMedium", serif;
  color: #264e72;
  font-size: 18px;
}

.how-modal__info p span {
  font-family: "FreightSansProBold", serif;
  color: #f89420;
  font-size: 18px;
}

.how-modal__info p strong {
  font-family: "FreightSansProBold", serif;
  color: #264e72;
  font-size: 18px;
}

.modal-step h1 {
  color: #004379;
  font-family: "FreightSansProBold", serif;
  font-size: 20px;
  margin-bottom: 16px;
}

.modal-step .modal-step-text img {
  margin-right: 12px;
  width: 80px;
}

.modal-step .modal-step-text {
  align-items: flex-start;
}

.modal-step .modal-step-text div {
  flex: 1;
  align-items: flex-start;
}

.modal-step .modal-step-text p {
  color: #004379;
  font-family: "FreightSansProMedium", serif;
  font-size: 14px;
}

.modal-step .modal-step-text p span {
  font-family: "FreightSansProMedium", serif;
  font-size: 14px;
  font-weight: bolder;
}

.modal-step .modal-step-text b {
  color: #004379;
  font-family: "FreightSansProBold", serif;
  font-size: 16px;
}

.modal-step .modal-step-text span {
  color: #f99420;
  font-family: "FreightSansProBold", serif;
  font-size: 16px;
}

.bg-modal {
  z-index: 998;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000000b4;
}

@media (min-width: 768px) {
  .how-modal {
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    padding: 24px;
  }
}

@media (min-width: 1024px) {
  .how-modal__info div:before {
    display: none;
  }

  .modal-step .modal-step-text p {
    font-size: 24px;
  }

  .modal-step .modal-step-text p b {
    font-size: 24px;
  }

  .modal-step .modal-step-text p span {
    font-size: 24px;
  }

  .modal-step .modal-step-text img {
    width: 250px;
  }

  .modal-step h1 {
    font-size: 36px;
  }

  .footer-text {
    font-size: 24px;
  }

  .footer-text strong {
    margin-right: 24px;
    font-size: 24px;
  }

  .how-modal__title {
    font-size: 48px;
    margin-bottom: 48px;
  }

  .how-modal {
    padding: 48px;
    width: calc(100% - 120px);
    height: calc(100% - 120px);
  }

  .how-modal__text p {
    font-size: 24px;
  }

  .how-modal__info div {
    align-items: center;
  }

  .how-modal__info p {
    font-size: 24px;
  }

  .how-modal__info--span {
    width: auto;
    height: auto;
    margin-right: 24px;
    background-color: transparent;
    color: #00aef0;
    font-size: 72px;
    font-family: "FreightSansProBold", serif;
  }

  .how-modal__info p span {
    font-size: 24px;
  }

  .how-modal__info p strong {
    font-size: 24px;
  }

  .how-modal__text strong {
    font-size: 24px;
  }
}
@media (min-width: 1200px) {
  .steps-vehicle p {
    font-size: 20px !important;
    margin-bottom: 12px !important;
    font-family: "FreightSansProMedium", serif !important;
  }

  .modal-step .steps-vehicle ul li {
    font-family: "FreightSansProMedium", serif;
    counter-increment: my-awesome-counter;
    color: #264e72;
    font-size: 20px;
    position: relative;
    margin-bottom: 32px;
    padding-left: 48px;
  }

  .steps-vehicle h1 {
    font-size: 24px !important;
    margin-bottom: 12px !important;
    font-family: "FreightSansProBold", serif !important;
    color: #00aef0 !important;
  }

  .steps-vehicle b {
    font-size: 20px !important;
    margin-bottom: 12px !important;
    font-family: "FreightSansProBold", serif !important;
  }

  .steps-vehicle span {
    font-size: 20px !important;
    margin-bottom: 12px !important;
    font-family: "FreightSansProBold", serif !important;
  }
}
