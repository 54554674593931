.products-container {
  background: var(--gray-third) 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  padding-bottom: 25px;
}

.products-container .title {
  font-size: 22px;
  padding-top: 25px;
}

.products-container .subtitle {
  font-size: 18px;
  font-family: "FreightSansProMedium", serif;
  text-align: center;
  color: #264e72;
  max-width: calc(100% - 64px);
  margin: 0 auto;
}
.values-slide-fix .products-container {
  padding-top: 0;
  background-color: #fff !important;
}

.products-container .hide-btn {
  text-align: center;
  color: var(--orange);
  font-family: "FreightSansProBold", serif;
}

.cards-container {
  margin-top: 25px;
  width: 100%;
  display: flex;
}

.cards-container .splide__arrows {
  margin-top: -120px;
  background: #000;
}
.cards-container .splide {
  width: 250px;
  height: 223px;
}
.cards-container .splide__slide {
  height: 223px;
}

.cards-container .splide__pagination__page {
  background-color: #ced3e2;
  width: 10px;
  height: 10px;
}

.cards-container .splide__pagination__page.is-active {
  background-color: #f89420;
  width: 10px;
  height: 10px;
}

.cards-container .splide__arrow {
  background-color: transparent;
}

.cards-container .splide__arrow--prev {
  margin-left: -50px;
}

.cards-container .splide__arrow--prev img {
  width: 20px;
}

.cards-container .splide__arrow--next {
  margin-right: -50px;
}
.cards-container .splide__arrow--next img {
  width: 20px;
  transform: rotate(180deg);
}

@media screen and (min-width: 375px) {
  .cards-container .splide {
    width: 284px;
    height: 223px;
  }
}
@media screen and (min-width: 768px) {
  .cards-container .splide {
    width: 420px;
    height: 250px;
  }
}
@media screen and (min-width: 1024px) {
  .products-container {
    padding: 50px 0;
  }
  .values-slide-fix .products-container {
    padding-top: 0;
    background-color: #fff !important;
  }
  .products-container .title {
    font-size: 32px;
    padding-bottom: 25px;
  }
  .products-container .subtitle {
    font-size: 24px;
    margin-bottom: 26px;
    max-width: 600px;
  }
}
@media screen and (min-width: 1200px) {
  .products-container {
    padding: 75px 0;
  }

  .products-container .title {
    font-size: 48px;
    padding-bottom: 25px;
  }

  .value-slide-fix .products-container {
    padding-top: 0;
    background-color: #fff !important;
  }
}
