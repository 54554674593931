.information-section {
  width: 100%;
  color: var(--primary-blue);
  background: var(--white);
}

.information-section .text-information h2 {
  font-size: 20px;
  font-family: "FreightSansProMedium", serif;
  margin-bottom: -5px;
}
.information-section .text-information h3 {
  font-size: 25px;
  font-family: "FreightSansProBold", serif;
}

.information-section .text-information h3 span {
  font-size: 25px;
  font-family: "FreightSansProBold", serif;
}

.information-section .text-information p {
  font-family: "FreightSansProMedium", serif;
  font-size: 18px;
  margin: 15px 0;
}

.information-section .text-information p b {
  font-family: "FreightSansProBold", serif;
  font-size: 17px;
}

.information-section .text-information p .rescate-text {
  font-family: "FreightSansProBold", serif;
  font-size: 17px;
  color: #00aef0;
}

@media screen and (min-width: 768px) {
  .information-section {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .information-section {
    margin: 30px 0;
  }

  .information-section .text-information {
    width: 100%;
    max-width: 700px;
  }
  .information-section .text-information h2 {
    font-size: 24px;

    margin-bottom: -15px;
  }
  .information-section .text-information h3 {
    font-size: 30px;
    font-family: "FreightSansProBold", serif;
  }

  .information-section .text-information h3 span {
    font-size: 30px;
    margin-top: -15px;
  }

  .information-section .text-information p {
    font-family: "FreightSansProMedium", serif;
    font-size: 18px;
  }

  .information-section .text-information p b {
    font-family: "FreightSansProBold", serif;
    font-size: 19px;
  }
}

@media screen and (min-width: 1280px) {
  .information-section .text-information {
    width: 100%;
    max-width: 850px;
    margin-top: 50px;
    /*     margin-bottom: 60px; */
  }
  .information-section .text-information h2 {
    font-size: 35px;
    margin-bottom: -15px;
  }
  .information-section .text-information h3 {
    font-size: 40px;
  }

  .information-section .text-information h3 span {
    font-size: 38px;
  }

  .information-section .text-information p {
    font-family: "FreightSansProMedium", serif;
    font-size: 20px;
  }
}
