.multiriskTable {
  width: 100%;
}

.multiriskTable .header {
  background: var(--primary-blue);
  color: var(--white);
  border-radius: 3px;
  padding: 10px 15px;
  font-family: "FreightSansProMedium", serif;
  font-size: 17px;
}
.multiriskTable .header h3 {
  font-family: "FreightSansProMedium", serif !important;
  font-size: 17px !important;
  color: var(--white) !important;
}

.multiriskTable .content-information {
  padding: 10px;
}
.content-information .top h4 {
  text-align: left;
  font-family: "FreightSansProBold", serif;
  font-size: 17px;
  color: var(--primary-blue);
}
.content-information .top {
  display: block;
}
.content-information .top ul {
  margin-top: 5px;
  font-family: "FreightSansProMedium", serif;
  font-size: 15px;
  color: var(--primary-blue);
  margin-bottom: 20px;
}

.content-information .top li {
  display: flex;
}
.content-information .top ul span {
  font-family: "FreightSansProBold", serif;
  font-size: 15px;
  color: var(--primary-blue);
}
.content-information .top ul li span {
  font-family: "FreightSansProMedium", serif;
  font-size: 15px;
  color: var(--primary-blue);
}
.content-information .top ul li img {
  width: 8px;
  margin-right: 5px;
}

.content-information .bottom {
  padding: 10px 15px;
  background: #f3f5f8;
  border-radius: 5px;
}

.content-information .bottom h4 {
  font-family: "FreightSansProBold", serif;
  font-size: 15px;
  color: var(--primary-blue);
}
.content-information .bottom p {
  font-family: "FreightSansProMedium", serif;
  font-size: 15px;
  color: var(--primary-blue);
}

@media screen and (min-width: 768px) {
  .multiriskTable .content-information li {
    margin-top: 5px;
    padding-left: 10px;
  }
  .content-information .description {
    padding: 10px 15px;
  }
  .content-information .description p {
    font-family: "FreightSansProMedium", serif;
    font-size: 17px;
    color: var(--primary-blue);
  }

  .content-information .top {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0px 15px;
  }
  .content-information .bottom {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  .content-information .bottom h4 {
    font-size: 17px;
  }
  .content-information .bottom p {
    width: 100%;

    font-size: 16px;
  }
  .content-information {
    background: #f3f5f8;
  }
}
@media screen and (min-width: 1280px) {
  .multiriskTable .header h3 {
    font-size: 19px !important;
  }
  .content-information .top {
    display: grid;
    grid-template-columns: 500px 1fr;
  }
  .content-information .description p {
    font-size: 18px;
  }

  .content-information .top h4 {
    font-size: 18px;
  }
  .content-information .top ul span {
    font-size: 18px;
  }
  .content-information .top ul li span {
    font-size: 18px;
  }
  .content-information .bottom {
    display: grid;
    grid-template-columns: 500px 1fr;
    margin: 10px 0;
  }
  .content-information .bottom h4 {
    font-size: 18px;
  }
  .content-information .bottom p {
    font-size: 18px;
  }
}
.content-information {
  border-radius: 5px;
}
