.our-history hr {
  margin: 20px 0;
  background-color: 3px solid #70707080;
  opacity: 1;
}

.our-history .section-head h5 {
  font-family: "FreightSansProBold", serif;
  color: var(--secondary-blue);
  font-size: 25px;
}
.our-history .image-container {
  width: 214px;
  height: 103px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 21px #0000000f;
  display: flex;
  align-items: center;
}
.our-history .image-container img {
  width: 169px;
  height: 70px;
}
.our-history .text-content p {
  font-family: "FreightSansProMedium", serif;
  font-size: 18px;
  color: #264e72;
}

.timeline-container {
  max-height: 1200px;
  overflow-y: auto;
}

.timeline-container::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}

.timeline-container::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: rgba(0, 0, 0, 0, 0.2);
  -webkit-border-radius: 7px;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

.timeline-container::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

.timeline-container::-webkit-scrollbar-corner {
  background-color: transparent;
}
.timeline-box {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.timeline-box .year-bubble {
  display: flex;
  align-items: center;

  position: relative;
  background: #00aabb;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  background: #264e72 0% 0% no-repeat padding-box;
  text-align: center;
}
.timeline-box .year-bubble p {
  width: 100%;

  font-family: "FreightSansProBold", serif;
  font-size: 20px;
  color: #00aef0;
}

.timeline-box .year-bubble:after {
  content: "";
  position: absolute;
  right: -15px;
  width: 0;
  height: 0;
  display: flex;
  border: 12px solid transparent;
  border-top-color: #264e72;
  border-bottom: 0;
  transform: rotate(-90deg);
}

.timeline-box .text-year {
  flex: 0 0 45%;
  padding: 12px;
  width: 45%;
}

.timeline-box .bubble-box {
  flex: 0 0 80px;
}

.timeline-box .text-year p {
  font-size: 16px;
  font-family: "FreightSansProMedium", serif;
  color: #264e72;
}
.timeline-bar {
  width: 10px;
  height: 100%;
  position: relative;
  background: #f89420;
  display: flex;
  align-items: center;
}

.timeline-bar .inner-circle {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  /* UI Properties */
  background: #264e72 0% 0% no-repeat padding-box;
  border: 2px solid #fff;
}

.timeline-bar .circle {
  border: 3px solid #f89420;
  border-radius: 50px;
  left: -3px;
  position: absolute;
}

@media (min-width: 768px) {
  .our-history .image-container {
    width: 20%;
  }
  .our-history .image-container img {
    width: 90%;
  }
  .our-history .text-content {
    width: 75%;
  }

  .timeline-container {
    width: 100%;
    overflow-x: auto;
    overflow-y: visible;
    padding: 20px 0;
    height: auto !important;
  }

  .timeline-box {
    width: 100%;
    min-width: 150px;
  }

  .timeline-box .text-year {
    padding: 12px;
    width: 100%;
    flex: 0 0 150px;
    height: 150px;
  }

  .timeline-box .year-bubble {
    justify-content: center;
    width: 60px;
    height: 60px;
  }

  .timeline-box .year-bubble:after {
    bottom: -9px;
    right: unset;
    transform: rotate(0deg);
  }

  .timeline-box .year-bubble-down:after {
    top: -9px;
    right: unset;
    transform: rotate(180deg);
  }
  .timeline-box .year-bubble p {
    font-size: 18px;
  }
  .timeline-box .text-year p,
  .text-year b {
    font-size: 12px;
    font-family: "FreightSansProMedium", serif;
    color: #264e72;
    text-align: center;
  }
  .timeline-bar {
    width: 100%;
    height: 10px;
    display: flex;
    flex: 0 0 10px;
    justify-content: center;
    margin: 10px 0;
  }

  .timeline-bar .circle {
    border: 3px solid #f89420;
    border-radius: 50px;
    left: unset;
    position: absolute;
  }
  .timeline-box .bubble-box {
    flex: 0 0 150px;
  }
}
@media (min-width: 1280px) {
  .our-history .text-content p,
  .text-content b {
    font-size: 20px;
  }

  .timeline-container {
    padding: 0;
  }
  .timeline-box {
    width: 100%;
    min-width: 170px;
  }

  .timeline-box .year-bubble {
    width: 80px;
    height: 80px;
    margin: 0 auto;
  }
  .timeline-box .year-bubble p {
    font-size: 20px;
  }

  .timeline-box .text-year p,
  .text-year b {
    font-size: 14px;
    font-family: "FreightSansProMedium", serif;
    color: #264e72;
    text-align: center;
  }
  .timeline-box .year-bubble:after {
    bottom: -8px;
  }

  .timeline-box .year-bubble-down:after {
    top: -8px;
  }
}
