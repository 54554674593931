.centro-container h3 {
  font-size: 25px;
  color: var(--primary-blue);
  font-family: "FreightSansProBold", serif;
  margin: 20px 0;
}
.location-box h4 {
  font-size: 20px;
  margin-bottom: 10px;
  color: var(--primary-blue);
  font-family: "FreightSansProBold", serif;
}
.location-box {
  margin-bottom: 20px;
  padding: 15px;
  background: #f3f5f8 0% 0% no-repeat padding-box;
  width: 100%;
}
.location-box span {
  font-size: 16px;
  color: var(--primary-blue);
  font-family: "FreightSansProMedium", serif;
}

.know-more h3 {
  font-size: 25px;
  color: var(--primary-blue);
  font-family: "FreightSansProBold", serif;
  margin-bottom: 20px;
}
.know-more a span {
  color: var(--primary-blue);
  font-family: "FreightSansProBold", serif;
}

@media screen and (min-width: 600px) {
  .location-container {
    display: flex;
    justify-content: space-between;
  }
  .location-box {
    width: 100%;
    max-width: 45%;
  }
}
@media screen and (min-width: 768px) {
  .centro-container {
    margin-left: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .location-container {
    display: flex;
    justify-content: space-between;
  }
  .location-box {
    width: 100%;
    max-width: 45%;
  }
}
@media screen and (min-width: 1024px) {
  .location-container {
    display: flex;
    justify-content: space-between;
  }
  .location-box {
    width: 100%;
    max-width: 340px;
  }
}

@media screen and (min-width: 1280px) {
  .centro-container {
    margin-left: 20px;
  }
  .location-container {
    width: 90%;
  }
  .location-box {
    width: 100%;
    max-width: 300px;
  }
}
