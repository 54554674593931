.work-slide,
.experience-slide {
  width: 100%;
}

.experience-slide .splide__list,
.video-slide .splide__list {
  width: 100%;
}

.experience-slide__card {
  max-width: 274px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 3px 21px #0000000f;
  border-radius: 10px;
  padding: 16px;
  border: 1px solid #f1f1f123;
}

.work-slide .splide__arrow {
  background-color: transparent !important;
}

.work-slide .splide__track {
  padding-bottom: 24px;
}

.work-slide svg path {
  stroke: #00aef0;
}

.experience-slide__card .quote {
  position: absolute;
  top: 45px;
  width: 22px;
  margin-bottom: 0;
}

.work-slide .splide__arrow svg,
.experience-slide .splide__arrow svg,
.video-slide .splide__arrow svg {
  height: 27px;
  width: 27px;
}

.experience-slide .splide__arrow,
.video-slide .splide__arrow {
  background-color: transparent !important;
}

.experience-slide svg path,
.video-slide svg path {
  stroke: #00aef0;
}

.work-slide button:disabled svg path {
  stroke: #e2e2e2;
}

.work-link {
  display: block;
  background-color: #f99420 !important;
  color: #fff !important;
  font-family: "FreightSansProBold", serif;
  font-size: 18px;
  text-align: center;
  border-radius: 8px;
  padding: 14px 24px;
  max-width: 300px;
  margin: 0 auto !important;
}
.work-link:active,
.work-link:focus,
.work-link:hover,
.work-link:visited,
.work-link:focus-visible,
.work-link:focus-within {
  background-color: #f99420 !important;
  color: #fff !important;
  margin: 0 auto !important;
}

.work-slide .splide__list {
  width: 100%;
}

.work-slide__card {
  padding: 12px;
  text-align: center;
}

.experience-slide .splide__track {
  padding-top: 32px;
  padding-bottom: 32px;
}

.work-slide__card h2 {
  color: #264e72;
  font-family: "FreightSansProBold", serif;
  font-size: 18px;
  margin-top: 16px;
}

.work-slide__card img {
  margin: 0 auto;
  height: 148px;
  width: 148px;
  object-fit: contain;
}

.experience-slide__card img {
  width: 52px;
  height: 52px;
  margin: 0 auto;
  margin-bottom: 12px;
}

.experience-slide .splide__pagination {
  bottom: 0;
}

.experience-slide .splide__pagination__page {
  background-color: #ced3e2;
}

.experience-slide .splide__pagination__page.is-active {
  background-color: #f89420;
}

.experience-slide__card p {
  font-family: "FreightSansProMedium", serif;
  color: #264e72;
  margin-bottom: 12px;
}

.experience-slide__card p:last-child {
  margin-bottom: 0;
}

.video-slide__card {
  width: 85%;
  margin: 0 auto;
}

.experience-slide__card h2 {
  font-family: "FreightSansProBold", serif;
  color: #264e72;
}

.video-container {
  background-color: #264e72;
  padding: 46px 0;
}

.experience-slide__card .splide__pagination {
  bottom: 0;
}

.video-box h1 {
  color: #ffffff;
  font-family: "FreightSansProBold", serif;
  font-size: 24px;
  margin-bottom: 12px;
  text-align: center;
}

.video-box p {
  color: #ffffff;
  font-family: "FreightSansProMedium", serif;
  font-size: 18px;
  text-align: center;
  margin-bottom: 12px;
}

@media (min-width: 768px) {
  .work-link {
    margin-top: 80px;
    font-size: 22px;
    padding: 16px 32px;
    max-width: 348px;
  }

  .experience-slide__card {
    max-width: 600px;
  }
}

@media (min-width: 1024px) {
  .experience-slide .splide__arrow svg,
  .work-slide .splide__arrow svg {
    width: 30px;
    height: 30px;
  }

  .experience-slide__card {
    max-width: none;
    padding: 24px;
  }

  .splide__slide .experience-slide__card {
    background-color: hsl(228, 26%, 96%);
    filter: grayscale();
    transform: scale(0.9);
    transition: all ease-out 0.1s;
  }

  .experience-slide__card img {
    width: 100px;
    height: 100px;
  }

  .experience-slide__card p {
    font-size: 20px;
  }

  .experience-slide__card h1 {
    font-size: 16px;
  }

  .splide__slide.is-active .experience-slide__card {
    background-color: #f3f4f8;
    filter: none;
    transform: scale(1);
  }

  .experience-slide__card .quote {
    width: 40px;
    height: 40px;
    top: 95px;
  }

  .video-box h1 {
    font-size: 48px;
  }

  .video-box p {
    font-size: 30px;
  }

  .video-slide__card {
    width: 100%;
    margin: 0 auto;
  }

  .video-container {
    padding: 80px 0;
  }

  .video-slide .splide__arrow .splide__arrow--next {
    right: -3em;
  }

  .video-slide .splide__arrow .splide__arrow--prev {
    left: -3em;
  }
}
