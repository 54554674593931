.comparator-container {
  width: 100%;
  position: fixed;
  background-color: var(--white);
  z-index: 1001;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}

.comparator-container .fullScreen-top {
  display: flex;
  justify-content: flex-end;
}

.comparator-container .title-container {
  text-align: center;
  color: var(--primary-blue) !important;
}
.comparator-container .title-container h1 {
  font-family: "FreightSansProMedium", serif;
  font-size: 22px;
}
.comparator-container .title-container h2 {
  font-family: "FreightSansProBold", serif;
  display: inline-block;
  font-size: 29px;
}
.comparator-container .title-container h3 {
  font-family: "FreightSansProBold", serif;
  font-size: 29px;
  display: inline-block;
}
.comparator-container .selector-div h5 {
  font-family: "FreightSansProMedium", serif;
  font-size: 19px;
  color: var(--primary-blue);
  margin-bottom: 15px;
}
.selector {
  box-shadow: 0px 0px 21px #00000015;
  border: 1px solid #c7c7c7;
  width: 337px;
  height: 49px;
  text-align: left;
  font: 17px "FreightSansProMedium", serif;
  background: url(../../../../assets/img/icons/chevron-down-gray.svg) white
    no-repeat 94%;
  color: #333333;
  opacity: 1;
  padding-left: 20px;
}
.selector-options {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 21px #00000014;
  overflow: auto;
  width: calc(100% - 1rem);
  max-height: 15rem;
  position: absolute;
  z-index: 10;
}

.selector-option {
  position: relative;
  cursor: default;
  user-select: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  padding-left: 20px;
}
.selector-option-title {
  opacity: 1;
  font: 15px "FreightSansProMedium", serif;
  letter-spacing: 0px;
  color: var(--primary-blue);
}

.comparator-container .splide {
  width: 100%;
  max-width: 237px;
  height: 160px;
}

.selector-btn-options {
  display: flex;
  margin-top: 25px;
}
.selector-btn {
  width: 237px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 112px;
  font-size: 24px;
  color: #fff;
  background: #f3f5f8;
  border-radius: 10px;
  font-family: "FreightSansProBold", serif;
}
.selector-btn-active {
  color: var(--white);
  background: #4ea8e2;
  text-align: center;
}

.selector-btn-options .splide__pagination__page {
  background: #b2c3dd 0% 0% no-repeat padding-box;
  width: 13px;
  height: 13px;
}
.selector-btn-options .splide__pagination__page.is-active {
  background: #4ea8e2;
  width: 10px;
  height: 10px;
}

@media screen and (min-width: 768px) {
  .comparator-container .splide {
    width: 100%;
    max-width: 250px;
    height: 180px;
  }

  .selector-btn {
    width: 250px;
    height: 122px;
  }
}

.accordeon-title {
  background: var(--primary-blue);
  color: var(--white);
  font: medium 17px "FreightSansProMedium", serif;
  text-align: center;
  padding: 10px;
}

.rotate-arrow-accordeon {
  transform: rotate(180deg);
}
