.planList-side-menu {
  width: 100%;
  max-width: 250px;
  background: #f7f8fb 0% 0% no-repeat padding-box;
  border: 1px solid var(--white);
  opacity: 1;
}

.planList-side-menu ul {
  width: 100%;
}

.planList-side-menu .list-item {
  cursor: pointer;
  width: 100%;
  min-height: 70px;
  border-left: 8px solid transparent;
  font-family: "FreightSansProBold", serif;
  color: #c6cbd2;
  font-size: 20px;
  display: flex;
  align-items: center;
  padding: 25px;
}

.planList-side-menu .list-item-active {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-left: 8px solid var(--orange);
  color: var(--primary-blue);
}

@media screen and (min-width: 1280px) {
  .planList-side-menu {
    width: 100%;
    max-width: 350px;
  }
}
