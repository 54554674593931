.sidemenu {
  width: 100%;
  position: fixed;
  background-color: var(--white);
  z-index: 1001;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}

@media (min-width: 768px) {
  .sidemenu {
    width: 400px;
  }
}

.sidemenu__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidemenu__close span {
  color: var(--primary-blue);
  font-size: 36px;
}

.sidemenu__middle {
  margin-top: 48px;
}

.sidemenu__link {
  border-bottom: 1px solid #c7c7c7;
  display: block;
  font-family: "FreightSansProMedium", serif;
  font-weight: 500;
  color: var(--primary-blue);

  position: relative;
  padding: 19px 0;
}
.sidemenu__link:visited {
  color: var(--primary-blue);
}
.sidemenu__link span {
  font-size: 20px;
}

.sidemenu__link:nth-child(1) {
  border-top: 1px solid #c7c7c7;
}

.sidemenu__bottom {
  margin-top: 48px;
}

.sidemenu__secondary-menu {
  margin-top: 80px;
}

.sidemenu__secondary-menu a {
  font-family: "FreightSansProMedium", serif;
  display: block;
  color: var(--primary-blue);
  font-size: 24px;
  padding: 10px 0;
}

.sidemenu__bold {
  background-color: var(--secondary-blue);
  color: var(--white);
  border-radius: 25px;
  padding: 10px 13px;
  font-size: 24px;
  font-family: "FreightSansProMedium", serif;
  display: block;
  text-align: center;
  max-width: 345px;
  margin: 0 auto;
}
.overlay-sidemenu {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: var(--primary-blue);
  z-index: 1000;
}

.office--sidemenu {
  width: 100%;
  margin-top: 24px;
  margin-left: 0;
  justify-content: center;
  align-items: center;
  color: #fff !important;
}

.office--sidemenu span {
  margin-left: 24px;
}

.office--sidemenu img {
  width: 8px;
  margin-top: 2px;
  margin-left: 15px;
  transform: rotate(0);
  transition: transform 0.3s;
}
