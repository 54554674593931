@import "./colors.css";
@import "../components/header/css/Header.css";
@import "./sharepointFix.css";
@import "./style.css";
@import "../components/HomeComponents/css/Home.css";
@import "../components/footer/css/Footer.css";
@import "../components/MenuLandingsLayout/css/MenuLandings.css";
@import "../components/VehiclesComponent/css/VehiclesGeneral.css";

/* Fonts  */
@font-face {
  font-family: "FreightSansProBold";

  src: url("../assets/fonts/FreightSansProBold-Regular.woff2") format("woff2"),
    url("../assets/fonts/FreightSansProBold-Regular.woff") format("woff");

  font-weight: bold;

  font-style: normal;

  font-display: swap;
}

@font-face {
  font-family: "FreightSansProBook";

  src: url("../assets/fonts/FreightSansProBook-Regular.woff2") format("woff2"),
    url("../assets/fonts/FreightSansProBook-Regular.woff") format("woff");

  font-weight: normal;

  font-style: normal;

  font-display: swap;
}

@font-face {
  font-family: "FreightSansProMedium";

  src: url("../assets/fonts/FreightSansProMedium-Regular.woff2") format("woff2"),
    url("../assets/fonts/FreightSansProMedium-Regular.woff") format("woff");

  font-weight: 500;

  font-style: normal;

  font-display: swap;
}

@font-face {
  font-family: "FreightSansProSemibold";
  src: url("../assets/fonts/FreightSansProSemibold-Regular.eot");
  src: local("FreightSans Pro Semibold"),
    local("../assets/fonts/FreightSansProSemibold-Regular"),
    url("../assets/fonts/FreightSansProSemibold-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/FreightSansProSemibold-Regular.woff2") format("woff2"),
    url("../assets/fonts/FreightSansProSemibold-Regular.woff") format("woff"),
    url("../assets/fonts/FreightSansProSemibold-Regular.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

/* //General css */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: 16px;
}

@media screen and (min-width: 1024px) {
  .title {
    margin-top: 25px;
  }
}
@media screen and (min-width: 1280px) {
  .title {
    margin-top: 5px;
  }
}
@media (min-width: 1024px) {
  .lg\:mt-0 {
    margin-top: 0px;
  }
  .lg\:gap-0 {
    gap: 0px;
  }
}
.container {
  padding: 0 32px;

  @media (min-width: 1024px) {
    padding: 0 32px;
  }

  @media (min-width: 1200px) {
    padding: 0;
  }

  @media (min-width: 1600px) {
    width: 100%;
    max-width: 1600px !important;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
