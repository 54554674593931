.more-info {
  margin: 25px 0;
  width: 100%;
  height: auto;
  background: var(--white) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 21px #0000000f;
  opacity: 1;
  font-family: "FreightSansProBold", serif;
  border-left: 7px solid var(--orange);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 20px;
  box-sizing: border-box;
  overflow: auto;
}

.more-info-title {
  font-size: 15px;
  font-family: "FreightSansProBold", serif;
  color: var(--primary-blue);
  margin-bottom: 10px;
}

.more-info a {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
}

.more-info span {
  font-size: 13px;
  color: var(--orange);
}

.more-info img {
  width: 13px;
  height: 13px;
  margin-right: 10px;
}

@media screen and (min-width: 1024px) {
  .more-info {
    margin: 25px 0;
    width: 100%;
    max-width: 400px;
  }
  .more-info a {
    margin-bottom: 10px;
  }

  .more-info-title {
    font-size: 18px;
  }

  .more-info span {
    font-size: 14px;
    color: var(--orange);
  }

  .more-info img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
}

@media screen and (min-width: 1280px) {
  .more-info {
    max-width: 450px;
    padding: 15px 30px;
  }

  .more-info-title {
    font-size: 18px;
  }

  .more-info span {
    font-size: 16px;
    color: var(--orange);
  }

  .more-info img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
}
