.tab-director {
  width: 100%;
  height: 56px;
  padding: 0 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 21px #00000015;
  opacity: 1;
  border-left: 7px solid var(--orange);
  font-family: "FreightSansProBold", serif;
  color: var(--primary-blue);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  position: relative;
}

.tab-director:after {
  content: "";
  width: 20px;
  height: 20px;
  flex: 0 0 20px;
  margin-left: 12px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: rotate(90deg);
  transition: all ease-out 0.15s;
  background-image: url(../../../assets/img/chevron-right-dark-blue.svg);
}

.tab-director.RRT__tab--selected:after {
  transform: rotate(-90deg);
}

.panel-director {
  padding: 24px 0;
}

@media (min-width: 1024px) {
  .tab-director:after {
    display: none;
  }

  .panel-director {
    padding: 24px 12px;
  }

  .tab-director {
    cursor: pointer;
    width: 100%;
    min-height: 70px;
    border-left: 8px solid transparent;
    font-family: "FreightSansProBold", serif;
    color: #c6cbd2;
    font-size: 20px;
    display: flex;
    align-items: center;
    padding: 25px;
    margin-bottom: 0;
    box-shadow: none;
    background: #f7f8fb 0% 0% no-repeat padding-box;
  }

  .RRT__tabs {
    background: #f7f8fb 0% 0% no-repeat padding-box;
    flex: 0 0 30%;
  }

  .tab-director.RRT__tab--selected {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-left: 8px solid var(--orange);
    color: var(--primary-blue);
  }

  .RRT__panel {
    flex: 1;
  }

  .RRT__container {
    display: flex;
  }
}
