.responsabilidad .splide.is-active .splide__list {
  width: 100% !important;
}

.modal-fix .modal-close-btn {
  position: absolute;
  right: 12px;
  top: 12px;
  width: 16px;
}

.responsabilidad .inner {
  overflow: hidden;
  position: relative;
}

.responsabilidad .splide__arrow {
  background-color: transparent;
}

.responsabilidad .splide__arrow svg {
  height: 37px;
}

.responsabilidad .splide__arrow svg path {
  stroke: #00aef0;
}

.responsabilidad .splide__arrow[disabled] svg path {
  stroke: #e2e2e2;
}

.responsabilidad img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: right;
  transition: all ease-out 0.5s;
  object-fit: cover;
  object-position: top center;
  z-index: 0;
}

.inner:hover .responsabilidad-slide__img {
  transform: scale(1.1);
}

.responsabilidad .overlay {
  position: absolute;
  background: transparent linear-gradient(180deg, #00000057 0%, #000000a7 100%)
    0% 0% no-repeat padding-box;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.responsabilidad .responsabilidad__block {
  display: flex;
  height: 337px;
  width: 100%;
  position: relative;
  align-items: flex-end;
  z-index: 3;
}

.responsabilidad .splide__pagination {
  bottom: -30px;
}

.responsabilidad .responsabilidad__info {
  width: 100%;
  padding: 0 22px 22px 22px;
}

.responsabilidad .responsabilidad__title {
  color: #fff;
  font-family: "FreightSansProBold", serif;
  font-size: 20px;
  line-height: 36px;
}

.responsabilidad .splide__pagination__page.is-active {
  background-color: #f89420;
}

.modal-contribution h1 {
  color: #264e72;
  font-size: 24px;
  font-family: "FreightSansProBold", serif;
  margin-bottom: 18px;
  padding-right: 32px;
}

.title.title--left {
  text-align: left;
}

.modal-fix {
  height: calc(100vh - 64px);
  overflow: auto;
}

.modal-contribution img {
  height: 300px;
  width: 100%;
  margin-bottom: 24px;
  border-radius: 5px;
  object-fit: cover;
  object-position: 50% 20%;
}

.modal-contribution p {
  font-size: 16px;
  color: #264e72;
  margin-bottom: 12px;
  font-family: "FreightSansProMedium", serif;
}

.modal-contribution p b,
.modal-contribution p span {
  font-family: "FreightSansProBold", serif;
}

@media (min-width: 1024px) {
  .responsabilidad__block {
    height: 550px !important;
  }

  .responsabilidad__block.responsabilidad__block--desktop {
    height: 275px !important;
  }

  .responsabilidad .responsabilidad__title {
    color: #fff;
    font-family: "FreightSansProSemibold", serif;
    font-size: 24px;
    line-height: 36px;
  }

  .responsabilidad .splide__arrow svg {
    height: 48px;
  }

  .modal-contribution {
    height: 100%;
  }

  .respo-modal-image {
    height: 100% !important;
    margin-bottom: 0 !important;
  }

  .content-fix {
    padding: 16px;
  }

  .modal-contribution h1 {
    padding-right: 0;
    font-size: 36px;
  }

  .modal-contribution p {
    padding-right: 0;
    font-size: 18px;
    line-height: 30px;
  }

  .modal-contribution p span,
  .modal-contribution p b {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .responsabilidad .responsabilidad__title {
    font-size: 32px;
    line-height: 36px;
  }

  .content-fix {
    padding: 36px;
  }
}
