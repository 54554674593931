.noticias .splide__arrows .left-arrow {
  transform: rotate(180deg);
}

.noticias__bottom {
  padding: 20px;
  min-height: 340px;
  color: #f89420 !important;
  margin-bottom: 10px;
}

.noticias__link {
  color: #f89420 !important;
}

.noticias__link img {
  width: 9px;
  margin-top: 2px;
  margin-left: 10px;
}

.noticias__title {
  color: var(--primary-blue);
  font-size: 22px;
  font-family: "FreightSansProBold", sans-serif;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 6px;
  line-height: 1.2;
}
.noticias__date {
  color: var(--gray-secondary);
  font-family: "FreightSansProMedium", serif;
  font-size: 20px;
}

.noticias__text {
  color: var(--primary-blue);
  font-family: "FreightSansProMedium", serif;
  font-size: 20px;
  margin: 10px 0;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 26px !important;
}

@media screen and (min-width: 768px) {
  .noticias__image {
    height: 400px;
    object-fit: cover;
  }
  .noticias__bottom {
    min-height: 280px;

    margin-bottom: 10px;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 1024px) {
  .noticias {
    margin-bottom: 25px;
  }
  .noticias__bottom {
    height: 320px;
    padding: 20px;
  }
  .noticias__image {
    height: 313px;
    object-fit: cover;
  }
  .noticias__date {
    font-size: 24px;
  }

  .noticias__title {
    padding: 0;
    margin: 0;
  }

  .top-section {
    height: 140px;
  }
  .bottom-section {
    height: 140px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1024px) {
  .noticias__bottom {
    height: 350px;
    padding: 25px;
  }
  .top-section {
    height: auto;
  }
  .bottom-section {
    height: auto;
  }
}

@media screen and (min-width: 1440px) {
  .noticias__bottom {
    height: 340px;
    padding: 25px;
  }
  .top-section {
    height: 120px;
  }
  .bottom-section {
    height: 140px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1600px) {
  .noticias__bottom {
    height: 310px;
  }

  .bottom-section {
    margin-bottom: 0px;
  }
}
@media screen and (min-width: 1440px) {
  .noticias .left-arrow {
    margin-left: 5rem;
  }
  .noticias .right-arrow {
    margin-right: 5rem;
  }
}
