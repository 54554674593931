.vehicle-plans hr {
  margin: 20px 0;
  background-color: 3px solid #70707080;
  opacity: 1;
}

.vehicle-plans {
  width: 100%;
  background: var(--white);
  margin-bottom: 10px;
}
.vehicle-plans .section-head h5 {
  font-family: "FreightSansProBold", serif;
  color: var(--secondary-blue);
  font-size: 25px;
}

.vehicle-plans .comparator-div {
  width: 100%;
  max-width: 165px;
  cursor: pointer;
}
.vehicle-plans .comparator-div .comparator-img {
  width: 21px;
  height: 21px;
}
.vehicle-plans .comparator-div .comparator-title {
  font-size: 18px;
  font-family: "FreightSansProBold", serif;
  color: var(--orange);
}

@media screen and (min-width: 1200px) {
  .vehicle-plans .section-head h5 {
    font-size: 30px;
  }

  .vehicle-plans .comparator-div {
    width: 100%;
    max-width: 200px;
  }

  .vehicle-plans .comparator-div .comparator-title {
    font-size: 22px;
    font-family: "FreightSansProBold", serif;
    color: var(--orange);
  }
}
