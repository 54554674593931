.asistance-line {
  background: var(--white) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 21px #00000015;
  opacity: 1;
  border-left: 4px solid var(--orange);
  padding: 20px;
  margin-bottom: 20px;
  color: var(--primary-blue);
}

.asistance-line p {
  font-family: "FreightSansProMedium", serif;
  color: #264e72;
  margin: 0 !important;
  line-height: normal !important;
}
.asistance-title {
  font-family: "FreightSansProBold", serif;
  font-size: 20px;
  color: #264e72 !important;
}

.orange-text {
  font-family: "FreightSansProBold", serif;
  font-size: 19px;
  color: var(--orange);
}
.asistance-line .blue-text {
  font-family: "FreightSansProBold", serif;
  font-size: 19px;
}

@media screen and (min-width: 1024px) {
  .asistance-line {
    margin: 50px 0;
    width: 100%;
    max-width: 500px;
  }
}
