.hero-section__img {
  width: 100%;
  position: absolute;
  height: 100%;
  object-fit: cover;
  object-position: left;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 0;
}

.splide__pagination__page.is-active {
  transform: none !important;
}

.splide__pagination__page {
  opacity: 1;
  border-radius: 50%;
  height: 10px !important;
  width: 10px !important;
}

@media screen and (min-width: 1024px) {
  .splide__pagination__page.is-active {
    width: 30px !important;
    border-radius: 14px !important;
  }
  .splide__pagination__page {
    height: 7.5px !important;
    margin: 3px 6px !important;
    width: 7.5px !important;
  }
}

.hero-section .left-arrow {
  margin-top: 30px;
}
