.need-help {
  margin: 25px 0;
  width: 100%;
  background: var(--white) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 21px #0000000f;
  opacity: 1;
  font-family: "FreightSansProBold", serif;
  border-left: 7px solid var(--orange);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 20px;
  box-sizing: border-box;
  overflow: auto;
}

.need-help-message {
  font-family: "FreightSansProMedium", serif;
  color: var(--primary-blue);
}

.need-help-title {
  font-size: 18px;
  font-family: "FreightSansProBold", serif;
  color: var(--primary-blue);
  margin-bottom: 27px;
  line-height: 24px;
}

.need-help-title.need-help-title--short {
  margin-bottom: 5px;
}

.need-help-block {
  margin-bottom: 27px;
  line-height: 20px;
}

.need-help-block.need-help-block--nomargin {
  margin-bottom: 0px;
  line-height: 20px;
}

.need-help-block a {
  display: block;
  font-size: 24px;
  font-family: "FreightSansProBold", serif;
  color: #264e72;
}

a.need-help-orange-large {
  font-family: "FreightSansProBold", serif;
  font-size: 24px;
  color: #f89420;
}

.need-help span {
  font-size: 22px;
  color: #00aef0;
}

.need-help img {
  width: 13px;
  height: 13px;
  margin-right: 10px;
}

@media screen and (min-width: 1024px) {
  .need-help {
    margin: 25px 0;
    width: 100%;
    max-width: 400px;
    padding: 20px;
  }

  /* .need-help a {
        margin-bottom: 10px;
    } */

  .need-help-title {
    font-size: 18px;
  }

  /* .need-help span {
        font-size: 14px;
        color: var(--orange);
    } */

  .need-help img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
}

@media screen and (min-width: 1280px) {
  .need-help {
    max-width: 450px;
    padding: 30px;
  }

  .need-help-title {
    font-size: 25px;
  }

  .need-help span {
    font-size: 22px;
    color: #00aef0;
  }

  .need-help img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
}
