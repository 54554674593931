.blue-arrow-list {
  width: 100%;
  margin-bottom: 20px;
}

.blue-arrow-list ul li img {
  width: 8.47px;
  margin-right: 10px;
}

.blue-arrow-list ul,
.blue-arrow-list h4,
.blue-arrow-list p {
  margin-left: 6px !important;
}

.titlebluebox .blue-arrow-list-title {
  color: #00aef0;
}

.blue-arrow-list-title {
  font-size: 20px;
  font-family: "FreightSansProBold", serif;
  letter-spacing: 0px;
  color: var(--primary-blue);
  margin-bottom: 15px;
}

.blue-arrow-list li {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  font-size: 18px;
  color: var(--primary-blue);
  font-family: "FreightSansProMedium", serif;
}

.blue-arrow-list h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: var(--primary-blue);
  font-family: "FreightSansProMedium", serif;
}

.blue-arrow-list li b {
  font-family: "FreightSansProBold", serif;
}

.blue-arrow-list h4 {
  color: var(--primary-blue);
  font-family: "FreightSansProBold", serif;
  font-size: 17px;
  margin-bottom: 10px;
}

.blue-arrow-list p {
  font-size: 15px;
  margin: 15px 0;
  color: var(--primary-blue);
  font-family: "FreightSansProMedium", serif;
}

@media screen and (min-width: 1024px) {
  .blue-arrow-list-title {
    font-size: 25px;
  }
}

@media screen and (min-width: 1280px) {
  .blue-arrow-list-title {
    font-size: 30px;
  }

  .blue-arrow-list h4 {
    font-size: 25px;
  }

  .blue-arrow-list p {
    font-size: 18px;
    margin: 15px 0;
    color: var(--primary-blue);
    font-family: "FreightSansProMedium", serif;
  }
}
