.app-download {
  width: 100%;
}
.app-download p {
  font-family: "FreightSansProBold", serif;
  color: var(--primary-blue);
  font-size: 17px;
  margin-bottom: 15px;
}

.app-download-links-container {
  display: flex;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .app-download p {
    font-size: 20px;
  }
  .app-download-links-container img {
    width: 150px;
  }
}
@media screen and (min-width: 1024px) {
  .app-download p {
    font-size: 25px;
    margin-bottom: 15px;
  }
  .app-download-links-container img {
    width: 180px;
  }
}
