.text-information img {
  margin-left: -20px;
}

@media screen and (min-width: 768px) {
  .text-information img {
    display: none;
  }
  .img-via-reservas {
    width: 200px;
    height: 200px;
    margin-left: -20px;
  }
}
@media screen and (min-width: 1024px) {
  .text-information img {
    display: none;
  }
  .img-via-reservas {
    width: 200px;
    height: 200px;
    margin-left: -20px;
  }
}
@media screen and (min-width: 1280px) {
  .img-via-reservas {
    width: 250px;
    height: 250px;
    margin-left: -20px;
  }
}
