.about-card {
    box-shadow: 0px 3px 21px #0000000F;
    border-radius: 10px;
    padding: 48px 24px;
    text-align: center;
}

.about-card h2 {
    color: #00aef0;
    font-size: 32px;
    font-family: "FreightSansProBold", serif;
    margin-bottom: 12px;
}

.values-card-fix {
    box-shadow: 0px 3px 21px #0000000f;
}

.about-card p {
    color: #264E72;
    font-size: 18px;
    font-family: "FreightSansProMedium", serif;
    line-height: 30px;
}

@media (min-width: 1024px) {
    .about-card h2 {
        font-size: 48px;
    }

    .about-card p {
        font-size: 24px;
        line-height: 32px;
    }
}