.news-details {
  width: 100%;
}
.news-details .orange-arrow {
  transform: rotate(180deg);
}
.news-details .back-btn {
  font-family: "FreightSansProBold", serif;
  color: #f89420;
}
.news-details .news-content {
  width: 100%;
}
.news-details .news-content .noticias__title {
  font-size: 25px;
  line-height: 1;
}
.news-details .news-content p,
.news-content h4 {
  font-family: "FreightSansProMedium", serif;
  font-size: 18px;
  color: #3a5e7f;
  margin-bottom: 20px;
}
.news-details .news-content h4 {
  font-family: "FreightSansProBold", serif;
}
.news-details .news-content img {
  display: none;
}

/*slider*/
.news-slider-container .splide__slide {
  height: 375px;

  display: flex;
  justify-content: center;
}
.news-slider-container .container--fix {
  top: -200px;
  bottom: unset;
  background: red;
}

.news-slider-container .splide__arrow--prev {
  margin-left: -40px;
}
.news-slider-container .splide__arrow--next {
  margin-right: -40px;
}

.news-slider-container .splide__arrow--prev img {
  width: 20px;
}

@media screen and (min-width: 470px) {
  .news-slider-container .splide__arrow--prev {
    margin-left: 0px;
  }
  .news-slider-container .splide__arrow--next {
    margin-right: 0px;
  }
}
@media screen and (min-width: 768px) {
  .news-slider-container .splide__slide {
    height: 375px;
  }
  .news-slider-container .splide__arrow--prev {
    margin-left: -40px;
  }
  .news-slider-container .splide__arrow--next {
    margin-right: -40px;
  }
}
@media screen and (min-width: 1024px) {
  .news-details .news-content .noticias__title {
    font-size: 35px;
    line-height: unset;
  }
  .news-details .news-content p,
  .news-content h4 {
    font-family: "FreightSansProMedium", serif;
    font-size: 18px;
    color: #3a5e7f;
    margin-bottom: 20px;
  }
  .news-details .news-content img {
    display: block;
    width: 100%;
    max-width: 500px;
    height: 320px;
    float: left;
    margin: 0 20px 20px 0;
  }
  .news-slider-container .splide__slide {
    height: 450px;
  }
}
@media screen and (min-width: 1280px) {
  .news-details .orange-arrow {
    width: 20px;
  }
  .news-details .back-btn {
    font-family: "FreightSansProBold", serif;
    color: #f89420;
    font-size: 22px;
  }
  .news-details .news-content .noticias__title {
    font-size: 45px;
  }
  .news-details .news-content .noticias__date {
    font-size: 22px;
  }
  .news-details .news-content p,
  .news-content h4 {
    font-size: 22px;
  }
  .news-details .news-content img {
    display: block;

    margin: 0 30px 18px 0;
    width: 100%;
    height: 355px;
  }

  /* Slider */
  .news-slider-container .container--fix {
    top: unset;
    bottom: unset;
    background: red;
  }

  .news-slider-container {
    padding: 0px !important;
  }
  .news-slider-container .splide__arrow--prev {
    margin-left: -15px;
  }
  .news-slider-container .splide__arrow--next {
    margin-right: -15px;
  }

  .news-slider-container .splide__slide {
    height: 550px;
  }

  .news-slider-container .splide__arrow--prev img,
  .splide__arrow--next img {
    width: 30px;
  }
}
