/* font-family: "FreightSansProBold", serif; */

.travel-icon-list {
  width: 100%;
}

.travel-icon-text {
  font-family: "FreightSansProBold", serif;
  color: #264e72;
  font-size: 17px;
  line-height: 25px;
  padding-right: 41px;
}

.travel-icon-block {
  align-items: center;
}

.travel-icon-image {
  width: 40px;
  height: 40px;
  margin-right: 22px;
}

@media (min-width: 1024px) {
  .travel-icon-block {
    padding-right: 0;
    text-align: center;
    margin: 0 auto;
  }

  .travel-icon-image {
    width: 80px;
    height: 80px;
    margin: 0 auto;
  }

  .travel-icon-text {
    margin-top: 29px;
    padding-right: 0;
  }
}
