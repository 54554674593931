.map-list__region {
  width: 100%;
  height: 56px;
  padding: 0 15px;
  background: #f3f4f8;
}

.map-list__region.map-list__region--active {
  background: #264e72;
}

.map-list__region.map-list__region--active h6 {
  color: #ffffff;
}

.map-marker {
  position: absolute;
  width: 60px;
  height: 60px;
  left: -30px;
  top: -60px;
}

.map-list__offices-list {
  background-color: #264e72;
  padding: 15px;
  max-height: 334px;
  overflow-y: auto;
}

.map-list__region h6 {
  font-family: "FreightSansProSemibold", serif;
  font-size: 16px;
  color: #264e72;
}

.map-list__region img {
  width: 18px;
}

.blue-arrow-rotate {
  width: 100%;
  max-width: 18px;
  transform: rotate(270deg);
  transition: all ease-in-out 0.2s;
}

.map-list__map-box {
  background-color: #ffffff;
  box-shadow: 0px 3px 21px #0000000f;
  height: 200px;
  margin-top: 24px;
  border-radius: 6px;
  overflow: hidden;
  border: 6px solid #ffffff;
}

.map-list__office-info {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  border-left: 7px solid #ffffff;
  padding: 16px;
  margin-bottom: 12px;
}

.map-list__office-info :last-of-type {
  margin-bottom: 0;
}

.map-list__office-info.map-list__office-info--active {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  border-left: 7px solid #f89420;
  padding: 16px;
}

.map-list__office-info h3 {
  font-family: "FreightSansProBold", serif;
  color: #264e72;
  font-size: 18px;
}

.map-list__office-info div {
  flex: 1;
}

.map-list__office-info p {
  font-family: "FreightSansProMedium", serif;
  color: #264e72;
  font-size: 16px;
}

.map-list__office-info p span {
  font-family: "FreightSansProBold", serif;
  color: #264e72;
  font-size: 16px;
}

.map-list__office-info button {
  margin-left: 4px;
  flex: 0 0 14px;
  border: none !important;
}
.map-list__office-info button:hover {
  background-color: #fff !important;
}

.map-list__office-info button img {
  width: 18px;
  height: 18px;
}

@media (min-width: 768px) {
  .map-list__map-box {
    height: 400px;
  }

  .map-list__region h6 {
    font-size: 20px;
  }
}
