#s4-bodyContainer .office {
  cursor: pointer;
}

#s4-bodyContainer a:hover {
  text-decoration: none !important;
}

#s4-bodyContainer img {
  border: none !important;
}

#s4-bodyContainer button {
  min-width: auto !important;
}

#s4-bodyContainer .seguros__button {
  color: #f89420 !important;
  text-align: center !important;
}

#s4-bodyContainer .seguros__link {
  color: #f89420 !important;
}

#s4-bodyContainer body {
  line-height: 26px !important;
}

#s4-bodyContainer .splide__arrow {
  border: none;
  min-width: 0;
}
#s4-bodyContainer .splide__arrow:hover,
#s4-bodyContainer .splide__arrow:active {
  border-color: transparent;
  background-color: transparent;
}

#s4-bodyContainer .noticias__button {
  color: #f89420 !important;
}

#s4-bodyContainer {
  padding-bottom: 0 !important;
}

#s4-bodyContainer .ms-rtestate-field p,
p.ms-rteElement-P {
  line-height: inherit !important;
}

#s4-bodyContainer .rotate-90 {
  transform: rotate(90deg);
}

.rotate-270deg {
  transform: rotate(270deg) !important;
}

.navbar-section-separator {
  border-left-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(196 196 196 / var(--tw-border-opacity));
  height: 1.75rem;
  width: 0.25rem;
}

.information-section .text-information p > span {
  color: #00aef0;
}
#headlessui-portal-root img,
#s4-bodyContainer img {
  border: none !important;
}

#RibbonContainer img {
  max-width: unset !important;
}

.h-5\/6 {
  height: 83.333333%;
}
