.adressmodal {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: var(--primary-blue);
  z-index: 999;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.adressmodal h3 {
  font-family: "FreightSansProMedium", serif;
  color: var(--white);
  margin-top: 25px;
  font-size: 23px;
  margin-bottom: 6px;
}

.adressmodal h3.no-margin {
  margin-top: 0;
  margin-bottom: 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.adressmodal.-translate-y-full {
  top: -100%;
}

.adressmodal:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent var(--primary-blue) transparent;
  top: -10px;
  left: 50%;
  margin-left: -10px;
}

.adressmodal__close {
  align-self: flex-end;
  opacity: 1;
  color: #424242;
  font-size: 36px;
  position: absolute;
  right: 15px;
  top: 30px;
  z-index: 9;
}

.adressmodal__close:hover {
  opacity: 1;
}

.adressmodal__title {
  font-family: "FreightSansProBold", serif;
  text-align: center;
  margin-bottom: 24px;
  color: var(--white);
  font-size: 30px;
}

.adressmodal__info {
  display: flex;
  color: var(--white);
  font-family: "FreightSansProBook", serif;
  font-size: 14px;
  border-bottom: 1px solid rgba(250, 250, 250, 0.3);
  margin-bottom: 24px;
  padding-bottom: 12px;
  letter-spacing: 0.8px;
}

@media (min-width: 1280px) {
  .adressmodal__info {
    font-size: 20px;
  }
}

.adressmodal__info:last-of-type {
  border: none;
  margin-bottom: 0;
}

.adressmodal__info strong {
  font-family: FreightSansProBold, serif;
}

.adressmodal__info .feather {
  margin-right: 12px;
  padding-top: 6px;
}

.adressmodal .generic-input {
  margin-top: 0;
  border-radius: 0;
}

.adressmodal--search {
  background-color: var(--white);
  width: 300px !important;
  height: auyo;
  padding: 0;
  border-radius: 10px;
  z-index: 9999;
  position: absolute;
  left: 50%;
  margin-left: -150px;
}

.adressmodal--search:before {
  display: none;
}

@media (min-width: 375px) {
  .adressmodal--search {
    background-color: var(--white);
    width: 90% !important;
    height: auto;
    left: 50%;
    margin-left: -45%;
  }
}

@media (min-width: 768px) {
  .adressmodal--search {
    background-color: var(--white);
    width: 600px !important;
    height: auto;
    left: 50%;
    margin-left: -300px;
    justify-content: center;
  }
}

@media (min-width: 1280px) {
  .adressmodal--search {
    background-color: var(--white);
    width: 850px !important;
    height: 650px;
    padding: 0;
    border-radius: 10px;
    z-index: 9999;
    position: absolute;
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: -425px;
  }

  .adressmodal--search .generic-input {
    border-bottom: 1px solid #c3c3c3;
    border-radius: 0;
  }

  .adressmodal--search .adressmodal__close {
    position: absolute;
    top: -60px;
    z-index: 1;
  }
  .adressmodal--search .adressmodal__close span {
    font-family: "FreightSansProMedium", serif;
    color: var(--white);
    font-size: 24px;
  }
}

.adressmodal--member {
  background-color: var(--white);
  width: 300px !important;
  height: auyo;
  padding: 0;
  border-radius: 10px;
  z-index: 9999;
  position: absolute;
  left: 50%;
  margin-left: -150px;
}

.adressmodal--member:before {
  display: none;
}

@media (min-width: 375px) {
  .adressmodal--member {
    background-color: var(--white);
    width: 90% !important;
    height: auto;
    left: 50%;
    margin-left: -45%;
  }
}

@media (min-width: 768px) {
  .adressmodal--member {
    background-color: var(--white);
    width: 600px !important;
    height: auto;
    left: 50%;
    margin-left: -300px;
    justify-content: center;
  }
}

@media (min-width: 1280px) {
  .adressmodal--member {
    background-color: var(--white);
    width: 850px !important;
    height: 650px;
    padding: 0;
    border-radius: 10px;
    z-index: 9999;
    position: absolute;
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: -425px;
  }
}
.footer__contact {
  display: flex;
  color: var(--white);
  margin-bottom: 12px;
  font-family: "FreightSansProMedium", serif;
  align-items: flex-start;
}
.list {
  color: var(--secondary-blue);
  font-size: 18px;
}
.list:visited {
  color: var(--secondary-blue);
}

.footer__contact span {
  flex: 1;
}

.footer__contact .blue {
  color: #89c6fc;
}

.footer__contact:last-of-type {
  margin-bottom: 0;
}

.footer__contact img {
  margin-right: 9px;
}

@media (min-width: 1280px) {
  .footer__social-box {
    display: flex;

    justify-content: flex-end;

    align-items: flex-start;
  }
}

.footer__social-box a {
  margin-right: 4px;
}

@media (min-width: 768px) {
  .footer__social-box a {
    margin-right: 12px;
  }
}

.footer__social-box a:last-child {
  margin-right: 0;
}

.footer__social-box a:hover {
  transition: all ease-in-out 1s;
  opacity: 1;
}

.footer__social-box a:hover img {
  transition: all ease-in-out 0.2s;
  transform: scale(1.2);
}
