.card-box-slide.card-box-slide--claims-fix,
.claims-channel-card {
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 3px 21px #0000000f;
}

.link-block {
  width: 100%;
}
.claims-list .splide {
  width: 250px;
  height: 223px;
}
.claims-list .splide__slide {
  height: 223px;
}
@media screen and (min-width: 375px) {
  .claims-list .splide {
    width: 284px;
    height: 223px;
  }
  .claims-list .splide__slide {
    height: 223px;
  }
}

@media screen and (min-width: 768px) {
  .claims-list .splide {
    width: 420px;
    height: 250px;
  }
  .claims-list .splide__slide {
    height: 250px;
  }
}

@media (max-width: 768px) {
  .desktop-slide-fix .hide-btn {
    display: none !important;
  }

  .claims-channel-card.claims-channel-card--fix p {
    margin-bottom: 12px !important;
    font-size: 14px !important;
    font-family: "FreightSansProBold", serif;
  }

  .claims-channel-card.claims-channel-card--fix {
    border: 1px solid #f1f1f1;
  }

  .claims-channel-card.claims-channel-card--fix img {
    width: 24px;
    height: 24px;
  }

  .claims-channel-card.claims-channel-card--fix .claims-channel-card__top {
    margin-bottom: 6px;
  }

  .claims-channel-card.claims-channel-card--fix h2 {
    font-family: "FreightSansProBold", serif;
    font-size: 16px;
  }

  .claims-channel-card.claims-channel-card--fix p a {
    font-size: 14px !important;
    color: #f89420;
    font-family: "FreightSansProBold", serif;
  }
  .claims-channel-card.claims-channel-card--fix p span {
    font-size: 14px !important;
    font-family: "FreightSansProBold", serif;
    color: #f89420;
    word-break: break-word;
  }

  .claims-channel-card.claims-channel-card--fix
    .link-block
    .claims-channels-button {
    font-family: "FreightSansProBold", serif;
    color: #00aef0;
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  .claims-channel-card.claims-channel-card--fix .link-block a {
    font-family: "FreightSansProBold", serif;
    color: #00aef0;
    display: flex;
    align-items: center;
    font-size: 12px;
  }
}

.claims-channel-card.claims-channel-card--fix
  .link-block
  .claims-channels-button
  img {
  width: 6px;
  margin-left: 6px;
}

.claims-channel-card.claims-channel-card--fix .link-block a img {
  width: 6px;
  margin-left: 6px;
}

.steps-slide-fix h2 {
  font-size: 20px;
  font-family: "FreightSansProMedium", serif;
  color: #264e72;
}

.steps-slide-fix .products-container {
  padding: 0 !important;
  background-color: #fff !important;
}

.steps-slide-fix h2 span {
  font-size: 24px;
  font-family: "FreightSansProBold", serif;
  color: #264e72;
}

.values-card {
  border-radius: 12px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 3px 21px #0000000f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
}

.values-card-fix img,
.values-card img {
  width: 90px;
  height: 45px;
  object-fit: contain;
  margin-bottom: 12px;
}

.desktop-slide-fix .hide-btn {
  display: none !important;
}

.steps-slide-fix .title,
.steps-slide-fix .hide-btn {
  display: none !important;
}

.steps-slide-fix .subtitle {
  text-align: left !important;
  width: 100% !important;
}

.values-card-fix h2,
.values-card h2 {
  font-family: "FreightSansProBold", serif;
  font-size: 24px;
  color: #264e72;
}

.values-slide-fix .hide-btn {
  display: none !important;
}

.claims-channel-card__top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.claims-channel-card__top img {
  width: 50px;
  height: 50px;
  margin-right: 16px;
}

.claims-channel-card__top h2 {
  flex: 1;
  font-family: "FreightSansProBold", serif;
  font-size: 16px;
  color: #264e72;
}

.card-box-slide--claims-fix p,
.claims-channel-card p {
  flex: 1;
  font-family: "FreightSansProMedium", serif;
  font-size: 15px;
  color: #264e72;
  line-height: 25px;
}

.card-box-slide--claims-fix p span,
.claims-channel-card p span,
.card-box-slide--claims-fix p a,
.claims-channel-card p a {
  font-family: "FreightSansProBold", serif;
  font-size: 15px;
  color: #f89420;
}

@media (min-width: 1024px) {
  .claims-channel-card.claims-channel-card--fix p {
    margin-bottom: 12px !important;
    font-family: "FreightSansProBold", serif;
    font-size: 18px;
  }

  .claims-channel-card.claims-channel-card--fix {
    background-color: transparent;
    box-shadow: none;
  }

  .claims-channel-card.claims-channel-card--fix h2 {
    font-family: "FreightSansProBold", serif;
    font-size: 24px;
  }

  .claims-channel-card.claims-channel-card--fix p span,
  .claims-channel-card.claims-channel-card--fix p a {
    font-family: "FreightSansProBold", serif;
    font-size: 17px;
    color: #f89420;
  }

  .link-block .claims-channels-button {
    font-family: "FreightSansProBold", serif;
    display: flex;
    color: #00aef0;
    align-items: center;
  }

  .link-block a {
    font-family: "FreightSansProBold", serif;
    display: flex;
    color: #00aef0;
    align-items: center;
  }

  .claims-channel-card__top img {
    width: 70px;
    height: 70px;
  }

  .claims-channel-card {
    padding: 32px;
  }

  .claims-channel-card__top h2 {
    font-size: 30px;
  }

  .card-box-slide--claims-fix p,
  .claims-channel-card p {
    font-size: 22px;
    line-height: 32px;
  }

  .steps-slide-fix h2 {
    font-size: 35px;
    font-family: "FreightSansProMedium", serif;
    color: #264e72;
  }

  .steps-slide-fix h2 span {
    font-size: 35px;
    font-family: "FreightSansProBold", serif;
    color: #264e72;
  }

  .card-box-slide--claims-fix p span,
  .claims-channel-card p span,
  .card-box-slide--claims-fix p a,
  .claims-channel-card p a {
    font-size: 22px;
  }
  .claims-list .splide {
    width: inherit;
    height: inherit;
  }
  .claims-list .splide__slide {
    height: inherit;
  }
}

@media (min-width: 1200px) {
  .claims-channel-card.claims-channel-card--fix p {
    margin-bottom: 12px !important;
    font-family: "FreightSansProBold", serif;
    font-size: 20px !important;
  }

  .claims-channel-card.claims-channel-card--fix {
    background-color: transparent;
    box-shadow: none;
  }

  .claims-channel-card.claims-channel-card--fix h2 {
    font-family: "FreightSansProBold", serif;
    font-size: 24px;
  }

  .claims-channel-card.claims-channel-card--fix p span {
    font-family: "FreightSansProBold", serif;
    font-size: 20px !important;
    color: #f89420;
  }

  .link-block .claims-channels-button {
    font-family: "FreightSansProBold", serif;
    display: flex;
    color: #00aef0;
    align-items: center;
  }

  .link-block a {
    font-family: "FreightSansProBold", serif;
    display: flex;
    color: #00aef0;
    align-items: center;
  }

  .values-card-fix h2,
  .values-card h2 {
    font-family: "FreightSansProBold", serif;
    font-size: 20px;
    color: #264e72;
    text-align: center;
  }

  .values-card-fix img,
  .values-card img {
    width: 80px;
    height: 36px;
    object-fit: contain;
    margin-bottom: 12px;
  }
}
