.icon-slider {
  display: flex;
  font-family: "FreightSansProBold", serif;
  margin-top: 40px;
}

.right-arrow {
  width: 30px;
  height: 61px;
  cursor: pointer;
}

.left-arrow {
  width: 30px;
  height: 61px;
  cursor: pointer;
}

.left-arrow-blue {
  width: 30px;
  height: 61px;
  transform: matrix(-1, 0, 0, -1, 0, 0);
  cursor: pointer;
}
.right-arrow-gray {
  width: 30px;
  height: 61px;
  transform: matrix(-1, 0, 0, -1, 0, 0);
  cursor: pointer;
}

.icon-slider .icons-container {
  position: relative;
  width: 100%;
  top: -20px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-columns: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
}
.icon-slider .icons-container .icon-box-general {
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: color 0.5s, border 0.5s;
}
.icon-slider .icons-container .icon-box {
  color: var(--gray-secondary);
  font-size: 18px;
  border-bottom: 6px solid var(--gray-primary);
  align-self: center;
}

.icon-slider .icons-container .icon-box-active {
  color: var(--secondary-blue);
  font-size: 18px;
  border-bottom: 6px solid var(--secondary-blue);
}

.icon-slider .icons-container img {
  width: 50px;
  min-height: 50px;
}
.icon-slider .icons-container p {
  font-size: 12px;
  text-align: center;
}

.seguros .mobile-slider .splide__slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.seguros .mobile-slider .splide__slide img {
  width: 70px;
  min-height: 60px;
}

.seguros .splide__arrow--next {
  display: none;
}
.seguros .splide__arrow--prev {
  display: none;
}
.mobile-slider {
  display: flex;

  justify-content: center;
}
.mobile-slider .right-arrow {
  margin-top: 20px;
}
.mobile-slider .left-arrow {
  margin-top: 20px;
}

.mobile-slider .left-arrow-blue {
  margin-top: 20px;
}

.mobile-slider .right-arrow-gray {
  margin-top: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content p {
  margin: 0;
  z-index: 3;
  color: var(--secondary-blue);
  font-family: "FreightSansProBold", serif;
}

@media screen and (min-width: 420px) {
  .icon-slider .icons-container .icon-box-general {
    height: 100px;
  }
  .icon-slider .icons-container img {
    width: 50px;
    min-height: 60px;
  }
}

@media screen and (min-width: 520px) {
  .icon-slider .icons-container .icon-box-general {
    height: 120px;
  }
  .icon-slider .icons-container img {
    width: 70px;
    min-height: 80px;
  }
  .icon-slider .icons-container p {
    font-size: 16px;
    text-align: center;
  }
}

@media screen and (min-width: 1280px) {
  .seguros__info {
    text-align: unset;
  }
}
